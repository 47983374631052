// source: pbtypes/vehicle/nav_cam_reconstruction/nav_cam_reconstruction.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_uuid_util_uuid_pb = require('../../../pbtypes/gen/uuid_util/uuid_pb.js');
goog.object.extend(proto, pbtypes_gen_uuid_util_uuid_pb);
var pbtypes_gen_body_trans_pb = require('../../../pbtypes/gen/body/trans_pb.js');
goog.object.extend(proto, pbtypes_gen_body_trans_pb);
var pbtypes_vehicle_nav_cam_reconstruction_pose_id_pb = require('../../../pbtypes/vehicle/nav_cam_reconstruction/pose_id_pb.js');
goog.object.extend(proto, pbtypes_vehicle_nav_cam_reconstruction_pose_id_pb);
goog.exportSymbol('proto.nav_cam_reconstruction.AddSubjectCamVertexRequest', null, global);
goog.exportSymbol('proto.nav_cam_reconstruction.AddSubjectCamVertexResponse', null, global);
goog.exportSymbol('proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure', null, global);
goog.exportSymbol('proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure.Reason', null, global);
goog.exportSymbol('proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.ResultCase', null, global);
goog.exportSymbol('proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success', null, global);
goog.exportSymbol('proto.nav_cam_reconstruction.FullMappingRequest', null, global);
goog.exportSymbol('proto.nav_cam_reconstruction.FullMappingResponse', null, global);
goog.exportSymbol('proto.nav_cam_reconstruction.LoadRequest', null, global);
goog.exportSymbol('proto.nav_cam_reconstruction.LoadResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nav_cam_reconstruction.LoadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nav_cam_reconstruction.LoadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nav_cam_reconstruction.LoadRequest.displayName = 'proto.nav_cam_reconstruction.LoadRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nav_cam_reconstruction.LoadResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nav_cam_reconstruction.LoadResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nav_cam_reconstruction.LoadResponse.displayName = 'proto.nav_cam_reconstruction.LoadResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nav_cam_reconstruction.FullMappingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nav_cam_reconstruction.FullMappingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nav_cam_reconstruction.FullMappingRequest.displayName = 'proto.nav_cam_reconstruction.FullMappingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nav_cam_reconstruction.FullMappingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nav_cam_reconstruction.FullMappingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nav_cam_reconstruction.FullMappingResponse.displayName = 'proto.nav_cam_reconstruction.FullMappingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nav_cam_reconstruction.AddSubjectCamVertexRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nav_cam_reconstruction.AddSubjectCamVertexRequest.displayName = 'proto.nav_cam_reconstruction.AddSubjectCamVertexRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.oneofGroups_);
};
goog.inherits(proto.nav_cam_reconstruction.AddSubjectCamVertexResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.displayName = 'proto.nav_cam_reconstruction.AddSubjectCamVertexResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success.displayName = 'proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure.displayName = 'proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nav_cam_reconstruction.LoadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.nav_cam_reconstruction.LoadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nav_cam_reconstruction.LoadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nav_cam_reconstruction.LoadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
requestUuid: (f = msg.getRequestUuid()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f),
loadPath: jspb.Message.getFieldWithDefault(msg, 3, ""),
incrementalSavePath: jspb.Message.getFieldWithDefault(msg, 4, ""),
estimateNcpgTNavTakeoff: (f = msg.getEstimateNcpgTNavTakeoff()) && pbtypes_gen_body_trans_pb.TransPb.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nav_cam_reconstruction.LoadRequest}
 */
proto.nav_cam_reconstruction.LoadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nav_cam_reconstruction.LoadRequest;
  return proto.nav_cam_reconstruction.LoadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nav_cam_reconstruction.LoadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nav_cam_reconstruction.LoadRequest}
 */
proto.nav_cam_reconstruction.LoadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setRequestUuid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoadPath(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIncrementalSavePath(value);
      break;
    case 5:
      var value = new pbtypes_gen_body_trans_pb.TransPb;
      reader.readMessage(value,pbtypes_gen_body_trans_pb.TransPb.deserializeBinaryFromReader);
      msg.setEstimateNcpgTNavTakeoff(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nav_cam_reconstruction.LoadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nav_cam_reconstruction.LoadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nav_cam_reconstruction.LoadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nav_cam_reconstruction.LoadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRequestUuid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getLoadPath();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIncrementalSavePath();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEstimateNcpgTNavTakeoff();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      pbtypes_gen_body_trans_pb.TransPb.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.nav_cam_reconstruction.LoadRequest.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.nav_cam_reconstruction.LoadRequest} returns this
 */
proto.nav_cam_reconstruction.LoadRequest.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uuid_util.Uuid request_uuid = 2;
 * @return {?proto.uuid_util.Uuid}
 */
proto.nav_cam_reconstruction.LoadRequest.prototype.getRequestUuid = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 2));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.nav_cam_reconstruction.LoadRequest} returns this
*/
proto.nav_cam_reconstruction.LoadRequest.prototype.setRequestUuid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.nav_cam_reconstruction.LoadRequest} returns this
 */
proto.nav_cam_reconstruction.LoadRequest.prototype.clearRequestUuid = function() {
  return this.setRequestUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nav_cam_reconstruction.LoadRequest.prototype.hasRequestUuid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string load_path = 3;
 * @return {string}
 */
proto.nav_cam_reconstruction.LoadRequest.prototype.getLoadPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.nav_cam_reconstruction.LoadRequest} returns this
 */
proto.nav_cam_reconstruction.LoadRequest.prototype.setLoadPath = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string incremental_save_path = 4;
 * @return {string}
 */
proto.nav_cam_reconstruction.LoadRequest.prototype.getIncrementalSavePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.nav_cam_reconstruction.LoadRequest} returns this
 */
proto.nav_cam_reconstruction.LoadRequest.prototype.setIncrementalSavePath = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional body.TransPb estimate_ncpg_t_nav_takeoff = 5;
 * @return {?proto.body.TransPb}
 */
proto.nav_cam_reconstruction.LoadRequest.prototype.getEstimateNcpgTNavTakeoff = function() {
  return /** @type{?proto.body.TransPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_body_trans_pb.TransPb, 5));
};


/**
 * @param {?proto.body.TransPb|undefined} value
 * @return {!proto.nav_cam_reconstruction.LoadRequest} returns this
*/
proto.nav_cam_reconstruction.LoadRequest.prototype.setEstimateNcpgTNavTakeoff = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.nav_cam_reconstruction.LoadRequest} returns this
 */
proto.nav_cam_reconstruction.LoadRequest.prototype.clearEstimateNcpgTNavTakeoff = function() {
  return this.setEstimateNcpgTNavTakeoff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nav_cam_reconstruction.LoadRequest.prototype.hasEstimateNcpgTNavTakeoff = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nav_cam_reconstruction.LoadResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.nav_cam_reconstruction.LoadResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nav_cam_reconstruction.LoadResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nav_cam_reconstruction.LoadResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
requestUuid: (f = msg.getRequestUuid()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f),
success: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nav_cam_reconstruction.LoadResponse}
 */
proto.nav_cam_reconstruction.LoadResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nav_cam_reconstruction.LoadResponse;
  return proto.nav_cam_reconstruction.LoadResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nav_cam_reconstruction.LoadResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nav_cam_reconstruction.LoadResponse}
 */
proto.nav_cam_reconstruction.LoadResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setRequestUuid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nav_cam_reconstruction.LoadResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nav_cam_reconstruction.LoadResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nav_cam_reconstruction.LoadResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nav_cam_reconstruction.LoadResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRequestUuid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.nav_cam_reconstruction.LoadResponse.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.nav_cam_reconstruction.LoadResponse} returns this
 */
proto.nav_cam_reconstruction.LoadResponse.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uuid_util.Uuid request_uuid = 2;
 * @return {?proto.uuid_util.Uuid}
 */
proto.nav_cam_reconstruction.LoadResponse.prototype.getRequestUuid = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 2));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.nav_cam_reconstruction.LoadResponse} returns this
*/
proto.nav_cam_reconstruction.LoadResponse.prototype.setRequestUuid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.nav_cam_reconstruction.LoadResponse} returns this
 */
proto.nav_cam_reconstruction.LoadResponse.prototype.clearRequestUuid = function() {
  return this.setRequestUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nav_cam_reconstruction.LoadResponse.prototype.hasRequestUuid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool success = 3;
 * @return {boolean}
 */
proto.nav_cam_reconstruction.LoadResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.nav_cam_reconstruction.LoadResponse} returns this
 */
proto.nav_cam_reconstruction.LoadResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nav_cam_reconstruction.FullMappingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.nav_cam_reconstruction.FullMappingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nav_cam_reconstruction.FullMappingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nav_cam_reconstruction.FullMappingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
requestUuid: (f = msg.getRequestUuid()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f),
fileUuid: (f = msg.getFileUuid()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f),
enable: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nav_cam_reconstruction.FullMappingRequest}
 */
proto.nav_cam_reconstruction.FullMappingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nav_cam_reconstruction.FullMappingRequest;
  return proto.nav_cam_reconstruction.FullMappingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nav_cam_reconstruction.FullMappingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nav_cam_reconstruction.FullMappingRequest}
 */
proto.nav_cam_reconstruction.FullMappingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setRequestUuid(value);
      break;
    case 3:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setFileUuid(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nav_cam_reconstruction.FullMappingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nav_cam_reconstruction.FullMappingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nav_cam_reconstruction.FullMappingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nav_cam_reconstruction.FullMappingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRequestUuid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getFileUuid();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getEnable();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.nav_cam_reconstruction.FullMappingRequest.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.nav_cam_reconstruction.FullMappingRequest} returns this
 */
proto.nav_cam_reconstruction.FullMappingRequest.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uuid_util.Uuid request_uuid = 2;
 * @return {?proto.uuid_util.Uuid}
 */
proto.nav_cam_reconstruction.FullMappingRequest.prototype.getRequestUuid = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 2));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.nav_cam_reconstruction.FullMappingRequest} returns this
*/
proto.nav_cam_reconstruction.FullMappingRequest.prototype.setRequestUuid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.nav_cam_reconstruction.FullMappingRequest} returns this
 */
proto.nav_cam_reconstruction.FullMappingRequest.prototype.clearRequestUuid = function() {
  return this.setRequestUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nav_cam_reconstruction.FullMappingRequest.prototype.hasRequestUuid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uuid_util.Uuid file_uuid = 3;
 * @return {?proto.uuid_util.Uuid}
 */
proto.nav_cam_reconstruction.FullMappingRequest.prototype.getFileUuid = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 3));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.nav_cam_reconstruction.FullMappingRequest} returns this
*/
proto.nav_cam_reconstruction.FullMappingRequest.prototype.setFileUuid = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.nav_cam_reconstruction.FullMappingRequest} returns this
 */
proto.nav_cam_reconstruction.FullMappingRequest.prototype.clearFileUuid = function() {
  return this.setFileUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nav_cam_reconstruction.FullMappingRequest.prototype.hasFileUuid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool enable = 4;
 * @return {boolean}
 */
proto.nav_cam_reconstruction.FullMappingRequest.prototype.getEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.nav_cam_reconstruction.FullMappingRequest} returns this
 */
proto.nav_cam_reconstruction.FullMappingRequest.prototype.setEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nav_cam_reconstruction.FullMappingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.nav_cam_reconstruction.FullMappingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nav_cam_reconstruction.FullMappingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nav_cam_reconstruction.FullMappingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
requestUuid: (f = msg.getRequestUuid()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f),
success: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nav_cam_reconstruction.FullMappingResponse}
 */
proto.nav_cam_reconstruction.FullMappingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nav_cam_reconstruction.FullMappingResponse;
  return proto.nav_cam_reconstruction.FullMappingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nav_cam_reconstruction.FullMappingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nav_cam_reconstruction.FullMappingResponse}
 */
proto.nav_cam_reconstruction.FullMappingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setRequestUuid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nav_cam_reconstruction.FullMappingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nav_cam_reconstruction.FullMappingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nav_cam_reconstruction.FullMappingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nav_cam_reconstruction.FullMappingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRequestUuid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.nav_cam_reconstruction.FullMappingResponse.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.nav_cam_reconstruction.FullMappingResponse} returns this
 */
proto.nav_cam_reconstruction.FullMappingResponse.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uuid_util.Uuid request_uuid = 2;
 * @return {?proto.uuid_util.Uuid}
 */
proto.nav_cam_reconstruction.FullMappingResponse.prototype.getRequestUuid = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 2));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.nav_cam_reconstruction.FullMappingResponse} returns this
*/
proto.nav_cam_reconstruction.FullMappingResponse.prototype.setRequestUuid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.nav_cam_reconstruction.FullMappingResponse} returns this
 */
proto.nav_cam_reconstruction.FullMappingResponse.prototype.clearRequestUuid = function() {
  return this.setRequestUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nav_cam_reconstruction.FullMappingResponse.prototype.hasRequestUuid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool success = 3;
 * @return {boolean}
 */
proto.nav_cam_reconstruction.FullMappingResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.nav_cam_reconstruction.FullMappingResponse} returns this
 */
proto.nav_cam_reconstruction.FullMappingResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.nav_cam_reconstruction.AddSubjectCamVertexRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nav_cam_reconstruction.AddSubjectCamVertexRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
requestUuid: (f = msg.getRequestUuid()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nav_cam_reconstruction.AddSubjectCamVertexRequest}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nav_cam_reconstruction.AddSubjectCamVertexRequest;
  return proto.nav_cam_reconstruction.AddSubjectCamVertexRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nav_cam_reconstruction.AddSubjectCamVertexRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nav_cam_reconstruction.AddSubjectCamVertexRequest}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setRequestUuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nav_cam_reconstruction.AddSubjectCamVertexRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nav_cam_reconstruction.AddSubjectCamVertexRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRequestUuid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexRequest.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.nav_cam_reconstruction.AddSubjectCamVertexRequest} returns this
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexRequest.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uuid_util.Uuid request_uuid = 2;
 * @return {?proto.uuid_util.Uuid}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexRequest.prototype.getRequestUuid = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 2));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.nav_cam_reconstruction.AddSubjectCamVertexRequest} returns this
*/
proto.nav_cam_reconstruction.AddSubjectCamVertexRequest.prototype.setRequestUuid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.nav_cam_reconstruction.AddSubjectCamVertexRequest} returns this
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexRequest.prototype.clearRequestUuid = function() {
  return this.setRequestUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexRequest.prototype.hasRequestUuid = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.oneofGroups_ = [[3,4]];

/**
 * @enum {number}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 3,
  FAILURE: 4
};

/**
 * @return {proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.ResultCase}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.prototype.getResultCase = function() {
  return /** @type {proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nav_cam_reconstruction.AddSubjectCamVertexResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
requestUuid: (f = msg.getRequestUuid()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f),
success: (f = msg.getSuccess()) && proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success.toObject(includeInstance, f),
failure: (f = msg.getFailure()) && proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nav_cam_reconstruction.AddSubjectCamVertexResponse}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nav_cam_reconstruction.AddSubjectCamVertexResponse;
  return proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nav_cam_reconstruction.AddSubjectCamVertexResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nav_cam_reconstruction.AddSubjectCamVertexResponse}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setRequestUuid(value);
      break;
    case 3:
      var value = new proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success;
      reader.readMessage(value,proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    case 4:
      var value = new proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure;
      reader.readMessage(value,proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure.deserializeBinaryFromReader);
      msg.setFailure(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nav_cam_reconstruction.AddSubjectCamVertexResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRequestUuid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success.serializeBinaryToWriter
    );
  }
  f = message.getFailure();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success.prototype.toObject = function(opt_includeInstance) {
  return proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success.toObject = function(includeInstance, msg) {
  var f, obj = {
addedPoseId: (f = msg.getAddedPoseId()) && pbtypes_vehicle_nav_cam_reconstruction_pose_id_pb.PoseIdPb.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success;
  return proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_vehicle_nav_cam_reconstruction_pose_id_pb.PoseIdPb;
      reader.readMessage(value,pbtypes_vehicle_nav_cam_reconstruction_pose_id_pb.PoseIdPb.deserializeBinaryFromReader);
      msg.setAddedPoseId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddedPoseId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_vehicle_nav_cam_reconstruction_pose_id_pb.PoseIdPb.serializeBinaryToWriter
    );
  }
};


/**
 * optional PoseIdPb added_pose_id = 1;
 * @return {?proto.nav_cam_reconstruction.PoseIdPb}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success.prototype.getAddedPoseId = function() {
  return /** @type{?proto.nav_cam_reconstruction.PoseIdPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_nav_cam_reconstruction_pose_id_pb.PoseIdPb, 1));
};


/**
 * @param {?proto.nav_cam_reconstruction.PoseIdPb|undefined} value
 * @return {!proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success} returns this
*/
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success.prototype.setAddedPoseId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success} returns this
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success.prototype.clearAddedPoseId = function() {
  return this.setAddedPoseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success.prototype.hasAddedPoseId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure.prototype.toObject = function(opt_includeInstance) {
  return proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure.toObject = function(includeInstance, msg) {
  var f, obj = {
reason: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure;
  return proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure.Reason} */ (reader.readEnum());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReason();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure.Reason = {
  INVALID: 0,
  NO_SUBJECT_CAM_INFO_AVAILABLE: 1,
  VERTEX_ALREADY_PRESENT: 2
};

/**
 * optional Reason reason = 1;
 * @return {!proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure.Reason}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure.prototype.getReason = function() {
  return /** @type {!proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure.Reason} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure.Reason} value
 * @return {!proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure} returns this
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure.prototype.setReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.nav_cam_reconstruction.AddSubjectCamVertexResponse} returns this
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uuid_util.Uuid request_uuid = 2;
 * @return {?proto.uuid_util.Uuid}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.prototype.getRequestUuid = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 2));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.nav_cam_reconstruction.AddSubjectCamVertexResponse} returns this
*/
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.prototype.setRequestUuid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.nav_cam_reconstruction.AddSubjectCamVertexResponse} returns this
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.prototype.clearRequestUuid = function() {
  return this.setRequestUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.prototype.hasRequestUuid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Success success = 3;
 * @return {?proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.prototype.getSuccess = function() {
  return /** @type{?proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success} */ (
    jspb.Message.getWrapperField(this, proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success, 3));
};


/**
 * @param {?proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Success|undefined} value
 * @return {!proto.nav_cam_reconstruction.AddSubjectCamVertexResponse} returns this
*/
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.nav_cam_reconstruction.AddSubjectCamVertexResponse} returns this
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Failure failure = 4;
 * @return {?proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.prototype.getFailure = function() {
  return /** @type{?proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure} */ (
    jspb.Message.getWrapperField(this, proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure, 4));
};


/**
 * @param {?proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.Failure|undefined} value
 * @return {!proto.nav_cam_reconstruction.AddSubjectCamVertexResponse} returns this
*/
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.prototype.setFailure = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.nav_cam_reconstruction.AddSubjectCamVertexResponse} returns this
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.prototype.clearFailure = function() {
  return this.setFailure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nav_cam_reconstruction.AddSubjectCamVertexResponse.prototype.hasFailure = function() {
  return jspb.Message.getField(this, 4) != null;
};


goog.object.extend(exports, proto.nav_cam_reconstruction);
