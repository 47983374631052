// source: pbtypes/vehicle/infrastructure/flight_deck/ucon.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_uuid_util_uuid_pb = require('../../../../pbtypes/gen/uuid_util/uuid_pb.js');
goog.object.extend(proto, pbtypes_gen_uuid_util_uuid_pb);
var pbtypes_tools_cloud_api_enums_pb = require('../../../../pbtypes/tools/cloud_api/enums_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_enums_pb);
var pbtypes_tools_cloud_api_ucon_pb = require('../../../../pbtypes/tools/cloud_api/ucon_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_ucon_pb);
goog.exportSymbol('proto.flight_deck.FileDeletionPolicy', null, global);
goog.exportSymbol('proto.flight_deck.FileDeletionPolicy.DeletionEnum', null, global);
goog.exportSymbol('proto.flight_deck.FileUploadRequest', null, global);
goog.exportSymbol('proto.flight_deck.FileUploadResponse', null, global);
goog.exportSymbol('proto.flight_deck.FileUploadResponse.ResponseEnum', null, global);
goog.exportSymbol('proto.flight_deck.FlightUpload', null, global);
goog.exportSymbol('proto.flight_deck.FlightUpload.ConflictPolicy', null, global);
goog.exportSymbol('proto.flight_deck.KindConfig', null, global);
goog.exportSymbol('proto.flight_deck.RemoveFlightsRequest', null, global);
goog.exportSymbol('proto.flight_deck.UconStatus', null, global);
goog.exportSymbol('proto.flight_deck.UconUploadRequest', null, global);
goog.exportSymbol('proto.flight_deck.UconUploadResponse', null, global);
goog.exportSymbol('proto.flight_deck.UconUploadResponse.ResponseEnum', null, global);
goog.exportSymbol('proto.flight_deck.UploadConfig', null, global);
goog.exportSymbol('proto.flight_deck.UploadFlightsRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.UconStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flight_deck.UconStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.UconStatus.displayName = 'proto.flight_deck.UconStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.UploadFlightsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.flight_deck.UploadFlightsRequest.repeatedFields_, null);
};
goog.inherits(proto.flight_deck.UploadFlightsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.UploadFlightsRequest.displayName = 'proto.flight_deck.UploadFlightsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.FlightUpload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flight_deck.FlightUpload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.FlightUpload.displayName = 'proto.flight_deck.FlightUpload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.UploadConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flight_deck.UploadConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.UploadConfig.displayName = 'proto.flight_deck.UploadConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.KindConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flight_deck.KindConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.KindConfig.displayName = 'proto.flight_deck.KindConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.FileDeletionPolicy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flight_deck.FileDeletionPolicy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.FileDeletionPolicy.displayName = 'proto.flight_deck.FileDeletionPolicy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.RemoveFlightsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.flight_deck.RemoveFlightsRequest.repeatedFields_, null);
};
goog.inherits(proto.flight_deck.RemoveFlightsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.RemoveFlightsRequest.displayName = 'proto.flight_deck.RemoveFlightsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.FileUploadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flight_deck.FileUploadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.FileUploadRequest.displayName = 'proto.flight_deck.FileUploadRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.FileUploadResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flight_deck.FileUploadResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.FileUploadResponse.displayName = 'proto.flight_deck.FileUploadResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.UconUploadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.flight_deck.UconUploadRequest.repeatedFields_, null);
};
goog.inherits(proto.flight_deck.UconUploadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.UconUploadRequest.displayName = 'proto.flight_deck.UconUploadRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.UconUploadResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.flight_deck.UconUploadResponse.repeatedFields_, null);
};
goog.inherits(proto.flight_deck.UconUploadResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.UconUploadResponse.displayName = 'proto.flight_deck.UconUploadResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.UconStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.UconStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.UconStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.UconStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
nonce: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.UconStatus}
 */
proto.flight_deck.UconStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.UconStatus;
  return proto.flight_deck.UconStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.UconStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.UconStatus}
 */
proto.flight_deck.UconStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNonce(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.UconStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.UconStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.UconStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.UconStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNonce();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.flight_deck.UconStatus.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.UconStatus} returns this
 */
proto.flight_deck.UconStatus.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 nonce = 2;
 * @return {number}
 */
proto.flight_deck.UconStatus.prototype.getNonce = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.UconStatus} returns this
 */
proto.flight_deck.UconStatus.prototype.setNonce = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.flight_deck.UploadFlightsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.UploadFlightsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.UploadFlightsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.UploadFlightsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.UploadFlightsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
flightsList: jspb.Message.toObjectList(msg.getFlightsList(),
    proto.flight_deck.FlightUpload.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.UploadFlightsRequest}
 */
proto.flight_deck.UploadFlightsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.UploadFlightsRequest;
  return proto.flight_deck.UploadFlightsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.UploadFlightsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.UploadFlightsRequest}
 */
proto.flight_deck.UploadFlightsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.flight_deck.FlightUpload;
      reader.readMessage(value,proto.flight_deck.FlightUpload.deserializeBinaryFromReader);
      msg.addFlights(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.UploadFlightsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.UploadFlightsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.UploadFlightsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.UploadFlightsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.flight_deck.FlightUpload.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FlightUpload flights = 1;
 * @return {!Array<!proto.flight_deck.FlightUpload>}
 */
proto.flight_deck.UploadFlightsRequest.prototype.getFlightsList = function() {
  return /** @type{!Array<!proto.flight_deck.FlightUpload>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.flight_deck.FlightUpload, 1));
};


/**
 * @param {!Array<!proto.flight_deck.FlightUpload>} value
 * @return {!proto.flight_deck.UploadFlightsRequest} returns this
*/
proto.flight_deck.UploadFlightsRequest.prototype.setFlightsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.flight_deck.FlightUpload=} opt_value
 * @param {number=} opt_index
 * @return {!proto.flight_deck.FlightUpload}
 */
proto.flight_deck.UploadFlightsRequest.prototype.addFlights = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.flight_deck.FlightUpload, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flight_deck.UploadFlightsRequest} returns this
 */
proto.flight_deck.UploadFlightsRequest.prototype.clearFlightsList = function() {
  return this.setFlightsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.FlightUpload.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.FlightUpload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.FlightUpload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.FlightUpload.toObject = function(includeInstance, msg) {
  var f, obj = {
flightId: jspb.Message.getFieldWithDefault(msg, 1, ""),
config: (f = msg.getConfig()) && proto.flight_deck.UploadConfig.toObject(includeInstance, f),
conflictPolicy: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.FlightUpload}
 */
proto.flight_deck.FlightUpload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.FlightUpload;
  return proto.flight_deck.FlightUpload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.FlightUpload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.FlightUpload}
 */
proto.flight_deck.FlightUpload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 2:
      var value = new proto.flight_deck.UploadConfig;
      reader.readMessage(value,proto.flight_deck.UploadConfig.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    case 3:
      var value = /** @type {!proto.flight_deck.FlightUpload.ConflictPolicy} */ (reader.readEnum());
      msg.setConflictPolicy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.FlightUpload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.FlightUpload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.FlightUpload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.FlightUpload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.flight_deck.UploadConfig.serializeBinaryToWriter
    );
  }
  f = message.getConflictPolicy();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.flight_deck.FlightUpload.ConflictPolicy = {
  MERGE: 0,
  OVERWRITE: 1
};

/**
 * optional string flight_id = 1;
 * @return {string}
 */
proto.flight_deck.FlightUpload.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.flight_deck.FlightUpload} returns this
 */
proto.flight_deck.FlightUpload.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional UploadConfig config = 2;
 * @return {?proto.flight_deck.UploadConfig}
 */
proto.flight_deck.FlightUpload.prototype.getConfig = function() {
  return /** @type{?proto.flight_deck.UploadConfig} */ (
    jspb.Message.getWrapperField(this, proto.flight_deck.UploadConfig, 2));
};


/**
 * @param {?proto.flight_deck.UploadConfig|undefined} value
 * @return {!proto.flight_deck.FlightUpload} returns this
*/
proto.flight_deck.FlightUpload.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.FlightUpload} returns this
 */
proto.flight_deck.FlightUpload.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.FlightUpload.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ConflictPolicy conflict_policy = 3;
 * @return {!proto.flight_deck.FlightUpload.ConflictPolicy}
 */
proto.flight_deck.FlightUpload.prototype.getConflictPolicy = function() {
  return /** @type {!proto.flight_deck.FlightUpload.ConflictPolicy} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.flight_deck.FlightUpload.ConflictPolicy} value
 * @return {!proto.flight_deck.FlightUpload} returns this
 */
proto.flight_deck.FlightUpload.prototype.setConflictPolicy = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.UploadConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.UploadConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.UploadConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.UploadConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
kindConfigMapMap: (f = msg.getKindConfigMapMap()) ? f.toObject(includeInstance, proto.flight_deck.KindConfig.toObject) : [],
deliveredFileMapMap: (f = msg.getDeliveredFileMapMap()) ? f.toObject(includeInstance, proto.cloud_api.FileDeliveryInfo.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.UploadConfig}
 */
proto.flight_deck.UploadConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.UploadConfig;
  return proto.flight_deck.UploadConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.UploadConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.UploadConfig}
 */
proto.flight_deck.UploadConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getKindConfigMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.flight_deck.KindConfig.deserializeBinaryFromReader, "", new proto.flight_deck.KindConfig());
         });
      break;
    case 2:
      var value = msg.getDeliveredFileMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.cloud_api.FileDeliveryInfo.deserializeBinaryFromReader, "", new proto.cloud_api.FileDeliveryInfo());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.UploadConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.UploadConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.UploadConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.UploadConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKindConfigMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.flight_deck.KindConfig.serializeBinaryToWriter);
  }
  f = message.getDeliveredFileMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.cloud_api.FileDeliveryInfo.serializeBinaryToWriter);
  }
};


/**
 * map<string, KindConfig> kind_config_map = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.flight_deck.KindConfig>}
 */
proto.flight_deck.UploadConfig.prototype.getKindConfigMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.flight_deck.KindConfig>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.flight_deck.KindConfig));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.flight_deck.UploadConfig} returns this
 */
proto.flight_deck.UploadConfig.prototype.clearKindConfigMapMap = function() {
  this.getKindConfigMapMap().clear();
  return this;
};


/**
 * map<string, cloud_api.FileDeliveryInfo> delivered_file_map = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.cloud_api.FileDeliveryInfo>}
 */
proto.flight_deck.UploadConfig.prototype.getDeliveredFileMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.cloud_api.FileDeliveryInfo>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.cloud_api.FileDeliveryInfo));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.flight_deck.UploadConfig} returns this
 */
proto.flight_deck.UploadConfig.prototype.clearDeliveredFileMapMap = function() {
  this.getDeliveredFileMapMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.KindConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.KindConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.KindConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.KindConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
kind: jspb.Message.getFieldWithDefault(msg, 1, ""),
priority: jspb.Message.getFieldWithDefault(msg, 2, 0),
fileDeletionPolicy: jspb.Message.getFieldWithDefault(msg, 3, 0),
fileUsage: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.KindConfig}
 */
proto.flight_deck.KindConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.KindConfig;
  return proto.flight_deck.KindConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.KindConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.KindConfig}
 */
proto.flight_deck.KindConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKind(value);
      break;
    case 2:
      var value = /** @type {!proto.cloud_api.UconUploadPriority.PriorityEnum} */ (reader.readEnum());
      msg.setPriority(value);
      break;
    case 3:
      var value = /** @type {!proto.flight_deck.FileDeletionPolicy.DeletionEnum} */ (reader.readEnum());
      msg.setFileDeletionPolicy(value);
      break;
    case 4:
      var value = /** @type {!proto.cloud_api.FileUsage.FileUsageReason} */ (reader.readEnum());
      msg.setFileUsage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.KindConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.KindConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.KindConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.KindConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKind();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPriority();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getFileDeletionPolicy();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getFileUsage();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string kind = 1;
 * @return {string}
 */
proto.flight_deck.KindConfig.prototype.getKind = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.flight_deck.KindConfig} returns this
 */
proto.flight_deck.KindConfig.prototype.setKind = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional cloud_api.UconUploadPriority.PriorityEnum priority = 2;
 * @return {!proto.cloud_api.UconUploadPriority.PriorityEnum}
 */
proto.flight_deck.KindConfig.prototype.getPriority = function() {
  return /** @type {!proto.cloud_api.UconUploadPriority.PriorityEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.cloud_api.UconUploadPriority.PriorityEnum} value
 * @return {!proto.flight_deck.KindConfig} returns this
 */
proto.flight_deck.KindConfig.prototype.setPriority = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional FileDeletionPolicy.DeletionEnum file_deletion_policy = 3;
 * @return {!proto.flight_deck.FileDeletionPolicy.DeletionEnum}
 */
proto.flight_deck.KindConfig.prototype.getFileDeletionPolicy = function() {
  return /** @type {!proto.flight_deck.FileDeletionPolicy.DeletionEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.flight_deck.FileDeletionPolicy.DeletionEnum} value
 * @return {!proto.flight_deck.KindConfig} returns this
 */
proto.flight_deck.KindConfig.prototype.setFileDeletionPolicy = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional cloud_api.FileUsage.FileUsageReason file_usage = 4;
 * @return {!proto.cloud_api.FileUsage.FileUsageReason}
 */
proto.flight_deck.KindConfig.prototype.getFileUsage = function() {
  return /** @type {!proto.cloud_api.FileUsage.FileUsageReason} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.cloud_api.FileUsage.FileUsageReason} value
 * @return {!proto.flight_deck.KindConfig} returns this
 */
proto.flight_deck.KindConfig.prototype.setFileUsage = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.FileDeletionPolicy.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.FileDeletionPolicy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.FileDeletionPolicy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.FileDeletionPolicy.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.FileDeletionPolicy}
 */
proto.flight_deck.FileDeletionPolicy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.FileDeletionPolicy;
  return proto.flight_deck.FileDeletionPolicy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.FileDeletionPolicy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.FileDeletionPolicy}
 */
proto.flight_deck.FileDeletionPolicy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.FileDeletionPolicy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.FileDeletionPolicy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.FileDeletionPolicy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.FileDeletionPolicy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.flight_deck.FileDeletionPolicy.DeletionEnum = {
  UNKNOWN: 0,
  NO_ACTION: 0,
  DELETE_AFTER_UPLOAD: 1
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.flight_deck.RemoveFlightsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.RemoveFlightsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.RemoveFlightsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.RemoveFlightsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.RemoveFlightsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
flightIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.RemoveFlightsRequest}
 */
proto.flight_deck.RemoveFlightsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.RemoveFlightsRequest;
  return proto.flight_deck.RemoveFlightsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.RemoveFlightsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.RemoveFlightsRequest}
 */
proto.flight_deck.RemoveFlightsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addFlightIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.RemoveFlightsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.RemoveFlightsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.RemoveFlightsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.RemoveFlightsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string flight_ids = 1;
 * @return {!Array<string>}
 */
proto.flight_deck.RemoveFlightsRequest.prototype.getFlightIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flight_deck.RemoveFlightsRequest} returns this
 */
proto.flight_deck.RemoveFlightsRequest.prototype.setFlightIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flight_deck.RemoveFlightsRequest} returns this
 */
proto.flight_deck.RemoveFlightsRequest.prototype.addFlightIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flight_deck.RemoveFlightsRequest} returns this
 */
proto.flight_deck.RemoveFlightsRequest.prototype.clearFlightIdsList = function() {
  return this.setFlightIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.FileUploadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.FileUploadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.FileUploadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.FileUploadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
uuid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.FileUploadRequest}
 */
proto.flight_deck.FileUploadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.FileUploadRequest;
  return proto.flight_deck.FileUploadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.FileUploadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.FileUploadRequest}
 */
proto.flight_deck.FileUploadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.FileUploadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.FileUploadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.FileUploadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.FileUploadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.flight_deck.FileUploadRequest.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.flight_deck.FileUploadRequest} returns this
 */
proto.flight_deck.FileUploadRequest.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.FileUploadResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.FileUploadResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.FileUploadResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.FileUploadResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
code: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.FileUploadResponse}
 */
proto.flight_deck.FileUploadResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.FileUploadResponse;
  return proto.flight_deck.FileUploadResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.FileUploadResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.FileUploadResponse}
 */
proto.flight_deck.FileUploadResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.flight_deck.FileUploadResponse.ResponseEnum} */ (reader.readEnum());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.FileUploadResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.FileUploadResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.FileUploadResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.FileUploadResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.flight_deck.FileUploadResponse.ResponseEnum = {
  UNKOWN: 0,
  SUCCESS: 1,
  UCONFILENOTFOUND: 2
};

/**
 * optional ResponseEnum code = 1;
 * @return {!proto.flight_deck.FileUploadResponse.ResponseEnum}
 */
proto.flight_deck.FileUploadResponse.prototype.getCode = function() {
  return /** @type {!proto.flight_deck.FileUploadResponse.ResponseEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.flight_deck.FileUploadResponse.ResponseEnum} value
 * @return {!proto.flight_deck.FileUploadResponse} returns this
 */
proto.flight_deck.FileUploadResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.flight_deck.UconUploadRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.UconUploadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.UconUploadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.UconUploadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.UconUploadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
uuid: (f = msg.getUuid()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f),
fileUploadRequestList: jspb.Message.toObjectList(msg.getFileUploadRequestList(),
    proto.flight_deck.FileUploadRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.UconUploadRequest}
 */
proto.flight_deck.UconUploadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.UconUploadRequest;
  return proto.flight_deck.UconUploadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.UconUploadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.UconUploadRequest}
 */
proto.flight_deck.UconUploadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setUuid(value);
      break;
    case 2:
      var value = new proto.flight_deck.FileUploadRequest;
      reader.readMessage(value,proto.flight_deck.FileUploadRequest.deserializeBinaryFromReader);
      msg.addFileUploadRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.UconUploadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.UconUploadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.UconUploadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.UconUploadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getFileUploadRequestList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.flight_deck.FileUploadRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional uuid_util.Uuid uuid = 1;
 * @return {?proto.uuid_util.Uuid}
 */
proto.flight_deck.UconUploadRequest.prototype.getUuid = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 1));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.flight_deck.UconUploadRequest} returns this
*/
proto.flight_deck.UconUploadRequest.prototype.setUuid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.UconUploadRequest} returns this
 */
proto.flight_deck.UconUploadRequest.prototype.clearUuid = function() {
  return this.setUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.UconUploadRequest.prototype.hasUuid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated FileUploadRequest file_upload_request = 2;
 * @return {!Array<!proto.flight_deck.FileUploadRequest>}
 */
proto.flight_deck.UconUploadRequest.prototype.getFileUploadRequestList = function() {
  return /** @type{!Array<!proto.flight_deck.FileUploadRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.flight_deck.FileUploadRequest, 2));
};


/**
 * @param {!Array<!proto.flight_deck.FileUploadRequest>} value
 * @return {!proto.flight_deck.UconUploadRequest} returns this
*/
proto.flight_deck.UconUploadRequest.prototype.setFileUploadRequestList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.flight_deck.FileUploadRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.flight_deck.FileUploadRequest}
 */
proto.flight_deck.UconUploadRequest.prototype.addFileUploadRequest = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.flight_deck.FileUploadRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flight_deck.UconUploadRequest} returns this
 */
proto.flight_deck.UconUploadRequest.prototype.clearFileUploadRequestList = function() {
  return this.setFileUploadRequestList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.flight_deck.UconUploadResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.UconUploadResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.UconUploadResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.UconUploadResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.UconUploadResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
requestUuid: (f = msg.getRequestUuid()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f),
code: jspb.Message.getFieldWithDefault(msg, 2, 0),
fileUploadResponseList: jspb.Message.toObjectList(msg.getFileUploadResponseList(),
    proto.flight_deck.FileUploadResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.UconUploadResponse}
 */
proto.flight_deck.UconUploadResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.UconUploadResponse;
  return proto.flight_deck.UconUploadResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.UconUploadResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.UconUploadResponse}
 */
proto.flight_deck.UconUploadResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setRequestUuid(value);
      break;
    case 2:
      var value = /** @type {!proto.flight_deck.UconUploadResponse.ResponseEnum} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 3:
      var value = new proto.flight_deck.FileUploadResponse;
      reader.readMessage(value,proto.flight_deck.FileUploadResponse.deserializeBinaryFromReader);
      msg.addFileUploadResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.UconUploadResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.UconUploadResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.UconUploadResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.UconUploadResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestUuid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getFileUploadResponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.flight_deck.FileUploadResponse.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.flight_deck.UconUploadResponse.ResponseEnum = {
  UNKNOWN: 0,
  SUCCESS: 1,
  FAILURE: 2,
  BADMESSAGE: 3,
  FLIGHTDECKAPIFAILURE: 4
};

/**
 * optional uuid_util.Uuid request_uuid = 1;
 * @return {?proto.uuid_util.Uuid}
 */
proto.flight_deck.UconUploadResponse.prototype.getRequestUuid = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 1));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.flight_deck.UconUploadResponse} returns this
*/
proto.flight_deck.UconUploadResponse.prototype.setRequestUuid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.UconUploadResponse} returns this
 */
proto.flight_deck.UconUploadResponse.prototype.clearRequestUuid = function() {
  return this.setRequestUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.UconUploadResponse.prototype.hasRequestUuid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResponseEnum code = 2;
 * @return {!proto.flight_deck.UconUploadResponse.ResponseEnum}
 */
proto.flight_deck.UconUploadResponse.prototype.getCode = function() {
  return /** @type {!proto.flight_deck.UconUploadResponse.ResponseEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.flight_deck.UconUploadResponse.ResponseEnum} value
 * @return {!proto.flight_deck.UconUploadResponse} returns this
 */
proto.flight_deck.UconUploadResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated FileUploadResponse file_upload_response = 3;
 * @return {!Array<!proto.flight_deck.FileUploadResponse>}
 */
proto.flight_deck.UconUploadResponse.prototype.getFileUploadResponseList = function() {
  return /** @type{!Array<!proto.flight_deck.FileUploadResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.flight_deck.FileUploadResponse, 3));
};


/**
 * @param {!Array<!proto.flight_deck.FileUploadResponse>} value
 * @return {!proto.flight_deck.UconUploadResponse} returns this
*/
proto.flight_deck.UconUploadResponse.prototype.setFileUploadResponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.flight_deck.FileUploadResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.flight_deck.FileUploadResponse}
 */
proto.flight_deck.UconUploadResponse.prototype.addFileUploadResponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.flight_deck.FileUploadResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flight_deck.UconUploadResponse} returns this
 */
proto.flight_deck.UconUploadResponse.prototype.clearFileUploadResponseList = function() {
  return this.setFileUploadResponseList([]);
};


goog.object.extend(exports, proto.flight_deck);
