// source: pbtypes/vehicle/skills/actions/goto_waypoint/goto_waypoint.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_vehicle_skills_waypoints_pb = require('../../../../../pbtypes/vehicle/skills/waypoints_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_waypoints_pb);
var pbtypes_vehicle_skills_image_waypoint_pb = require('../../../../../pbtypes/vehicle/skills/image_waypoint_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_image_waypoint_pb);
var pbtypes_gen_sensors_gps_source_pb = require('../../../../../pbtypes/gen/sensors/gps_source_pb.js');
goog.object.extend(proto, pbtypes_gen_sensors_gps_source_pb);
goog.exportSymbol('proto.skills.GotoGpsWaypointActionArgs', null, global);
goog.exportSymbol('proto.skills.GotoGpsWaypointActionStateData', null, global);
goog.exportSymbol('proto.skills.GotoGpsWaypointResultData', null, global);
goog.exportSymbol('proto.skills.GotoNavWaypointActionArgs', null, global);
goog.exportSymbol('proto.skills.GotoNavWaypointActionStateData', null, global);
goog.exportSymbol('proto.skills.GotoNavWaypointResultData', null, global);
goog.exportSymbol('proto.skills.GotoWaypointActionArgs', null, global);
goog.exportSymbol('proto.skills.GotoWaypointActionStateData', null, global);
goog.exportSymbol('proto.skills.GotoWaypointResultData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.GotoGpsWaypointActionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.GotoGpsWaypointActionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.GotoGpsWaypointActionArgs.displayName = 'proto.skills.GotoGpsWaypointActionArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.GotoNavWaypointActionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.GotoNavWaypointActionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.GotoNavWaypointActionArgs.displayName = 'proto.skills.GotoNavWaypointActionArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.GotoWaypointActionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.GotoWaypointActionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.GotoWaypointActionArgs.displayName = 'proto.skills.GotoWaypointActionArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.GotoGpsWaypointResultData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.GotoGpsWaypointResultData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.GotoGpsWaypointResultData.displayName = 'proto.skills.GotoGpsWaypointResultData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.GotoNavWaypointResultData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.GotoNavWaypointResultData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.GotoNavWaypointResultData.displayName = 'proto.skills.GotoNavWaypointResultData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.GotoWaypointResultData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.GotoWaypointResultData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.GotoWaypointResultData.displayName = 'proto.skills.GotoWaypointResultData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.GotoGpsWaypointActionStateData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.GotoGpsWaypointActionStateData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.GotoGpsWaypointActionStateData.displayName = 'proto.skills.GotoGpsWaypointActionStateData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.GotoNavWaypointActionStateData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.GotoNavWaypointActionStateData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.GotoNavWaypointActionStateData.displayName = 'proto.skills.GotoNavWaypointActionStateData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.GotoWaypointActionStateData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.GotoWaypointActionStateData.repeatedFields_, null);
};
goog.inherits(proto.skills.GotoWaypointActionStateData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.GotoWaypointActionStateData.displayName = 'proto.skills.GotoWaypointActionStateData';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.GotoGpsWaypointActionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.GotoGpsWaypointActionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.GotoGpsWaypointActionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GotoGpsWaypointActionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
waypoint: (f = msg.getWaypoint()) && pbtypes_vehicle_skills_waypoints_pb.Waypoint.toObject(includeInstance, f),
motionArgs: (f = msg.getMotionArgs()) && pbtypes_vehicle_skills_waypoints_pb.MotionArgs.toObject(includeInstance, f),
freeLookMode: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.GotoGpsWaypointActionArgs}
 */
proto.skills.GotoGpsWaypointActionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.GotoGpsWaypointActionArgs;
  return proto.skills.GotoGpsWaypointActionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.GotoGpsWaypointActionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.GotoGpsWaypointActionArgs}
 */
proto.skills.GotoGpsWaypointActionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_vehicle_skills_waypoints_pb.Waypoint;
      reader.readMessage(value,pbtypes_vehicle_skills_waypoints_pb.Waypoint.deserializeBinaryFromReader);
      msg.setWaypoint(value);
      break;
    case 2:
      var value = new pbtypes_vehicle_skills_waypoints_pb.MotionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_waypoints_pb.MotionArgs.deserializeBinaryFromReader);
      msg.setMotionArgs(value);
      break;
    case 5:
      var value = /** @type {!proto.skills.FreeLookMode.Enum} */ (reader.readEnum());
      msg.setFreeLookMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.GotoGpsWaypointActionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.GotoGpsWaypointActionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.GotoGpsWaypointActionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GotoGpsWaypointActionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWaypoint();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_vehicle_skills_waypoints_pb.Waypoint.serializeBinaryToWriter
    );
  }
  f = message.getMotionArgs();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_vehicle_skills_waypoints_pb.MotionArgs.serializeBinaryToWriter
    );
  }
  f = message.getFreeLookMode();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional Waypoint waypoint = 1;
 * @return {?proto.skills.Waypoint}
 */
proto.skills.GotoGpsWaypointActionArgs.prototype.getWaypoint = function() {
  return /** @type{?proto.skills.Waypoint} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_waypoints_pb.Waypoint, 1));
};


/**
 * @param {?proto.skills.Waypoint|undefined} value
 * @return {!proto.skills.GotoGpsWaypointActionArgs} returns this
*/
proto.skills.GotoGpsWaypointActionArgs.prototype.setWaypoint = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.GotoGpsWaypointActionArgs} returns this
 */
proto.skills.GotoGpsWaypointActionArgs.prototype.clearWaypoint = function() {
  return this.setWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.GotoGpsWaypointActionArgs.prototype.hasWaypoint = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MotionArgs motion_args = 2;
 * @return {?proto.skills.MotionArgs}
 */
proto.skills.GotoGpsWaypointActionArgs.prototype.getMotionArgs = function() {
  return /** @type{?proto.skills.MotionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_waypoints_pb.MotionArgs, 2));
};


/**
 * @param {?proto.skills.MotionArgs|undefined} value
 * @return {!proto.skills.GotoGpsWaypointActionArgs} returns this
*/
proto.skills.GotoGpsWaypointActionArgs.prototype.setMotionArgs = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.GotoGpsWaypointActionArgs} returns this
 */
proto.skills.GotoGpsWaypointActionArgs.prototype.clearMotionArgs = function() {
  return this.setMotionArgs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.GotoGpsWaypointActionArgs.prototype.hasMotionArgs = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FreeLookMode.Enum free_look_mode = 5;
 * @return {!proto.skills.FreeLookMode.Enum}
 */
proto.skills.GotoGpsWaypointActionArgs.prototype.getFreeLookMode = function() {
  return /** @type {!proto.skills.FreeLookMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.skills.FreeLookMode.Enum} value
 * @return {!proto.skills.GotoGpsWaypointActionArgs} returns this
 */
proto.skills.GotoGpsWaypointActionArgs.prototype.setFreeLookMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.GotoNavWaypointActionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.GotoNavWaypointActionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.GotoNavWaypointActionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GotoNavWaypointActionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
waypoint: (f = msg.getWaypoint()) && pbtypes_vehicle_skills_waypoints_pb.Waypoint.toObject(includeInstance, f),
motionArgs: (f = msg.getMotionArgs()) && pbtypes_vehicle_skills_waypoints_pb.MotionArgs.toObject(includeInstance, f),
freeLookMode: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.GotoNavWaypointActionArgs}
 */
proto.skills.GotoNavWaypointActionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.GotoNavWaypointActionArgs;
  return proto.skills.GotoNavWaypointActionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.GotoNavWaypointActionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.GotoNavWaypointActionArgs}
 */
proto.skills.GotoNavWaypointActionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_vehicle_skills_waypoints_pb.Waypoint;
      reader.readMessage(value,pbtypes_vehicle_skills_waypoints_pb.Waypoint.deserializeBinaryFromReader);
      msg.setWaypoint(value);
      break;
    case 2:
      var value = new pbtypes_vehicle_skills_waypoints_pb.MotionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_waypoints_pb.MotionArgs.deserializeBinaryFromReader);
      msg.setMotionArgs(value);
      break;
    case 5:
      var value = /** @type {!proto.skills.FreeLookMode.Enum} */ (reader.readEnum());
      msg.setFreeLookMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.GotoNavWaypointActionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.GotoNavWaypointActionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.GotoNavWaypointActionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GotoNavWaypointActionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWaypoint();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_vehicle_skills_waypoints_pb.Waypoint.serializeBinaryToWriter
    );
  }
  f = message.getMotionArgs();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_vehicle_skills_waypoints_pb.MotionArgs.serializeBinaryToWriter
    );
  }
  f = message.getFreeLookMode();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional Waypoint waypoint = 1;
 * @return {?proto.skills.Waypoint}
 */
proto.skills.GotoNavWaypointActionArgs.prototype.getWaypoint = function() {
  return /** @type{?proto.skills.Waypoint} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_waypoints_pb.Waypoint, 1));
};


/**
 * @param {?proto.skills.Waypoint|undefined} value
 * @return {!proto.skills.GotoNavWaypointActionArgs} returns this
*/
proto.skills.GotoNavWaypointActionArgs.prototype.setWaypoint = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.GotoNavWaypointActionArgs} returns this
 */
proto.skills.GotoNavWaypointActionArgs.prototype.clearWaypoint = function() {
  return this.setWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.GotoNavWaypointActionArgs.prototype.hasWaypoint = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MotionArgs motion_args = 2;
 * @return {?proto.skills.MotionArgs}
 */
proto.skills.GotoNavWaypointActionArgs.prototype.getMotionArgs = function() {
  return /** @type{?proto.skills.MotionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_waypoints_pb.MotionArgs, 2));
};


/**
 * @param {?proto.skills.MotionArgs|undefined} value
 * @return {!proto.skills.GotoNavWaypointActionArgs} returns this
*/
proto.skills.GotoNavWaypointActionArgs.prototype.setMotionArgs = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.GotoNavWaypointActionArgs} returns this
 */
proto.skills.GotoNavWaypointActionArgs.prototype.clearMotionArgs = function() {
  return this.setMotionArgs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.GotoNavWaypointActionArgs.prototype.hasMotionArgs = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FreeLookMode.Enum free_look_mode = 5;
 * @return {!proto.skills.FreeLookMode.Enum}
 */
proto.skills.GotoNavWaypointActionArgs.prototype.getFreeLookMode = function() {
  return /** @type {!proto.skills.FreeLookMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.skills.FreeLookMode.Enum} value
 * @return {!proto.skills.GotoNavWaypointActionArgs} returns this
 */
proto.skills.GotoNavWaypointActionArgs.prototype.setFreeLookMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.GotoWaypointActionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.GotoWaypointActionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.GotoWaypointActionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GotoWaypointActionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
waypoint: (f = msg.getWaypoint()) && pbtypes_vehicle_skills_waypoints_pb.Waypoint.toObject(includeInstance, f),
motionArgs: (f = msg.getMotionArgs()) && pbtypes_vehicle_skills_waypoints_pb.MotionArgs.toObject(includeInstance, f),
ncpgInfo: (f = msg.getNcpgInfo()) && pbtypes_vehicle_skills_image_waypoint_pb.NcpgInfo.toObject(includeInstance, f),
preserveArScene: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
freeLookMode: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.GotoWaypointActionArgs}
 */
proto.skills.GotoWaypointActionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.GotoWaypointActionArgs;
  return proto.skills.GotoWaypointActionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.GotoWaypointActionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.GotoWaypointActionArgs}
 */
proto.skills.GotoWaypointActionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_vehicle_skills_waypoints_pb.Waypoint;
      reader.readMessage(value,pbtypes_vehicle_skills_waypoints_pb.Waypoint.deserializeBinaryFromReader);
      msg.setWaypoint(value);
      break;
    case 2:
      var value = new pbtypes_vehicle_skills_waypoints_pb.MotionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_waypoints_pb.MotionArgs.deserializeBinaryFromReader);
      msg.setMotionArgs(value);
      break;
    case 3:
      var value = new pbtypes_vehicle_skills_image_waypoint_pb.NcpgInfo;
      reader.readMessage(value,pbtypes_vehicle_skills_image_waypoint_pb.NcpgInfo.deserializeBinaryFromReader);
      msg.setNcpgInfo(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPreserveArScene(value);
      break;
    case 5:
      var value = /** @type {!proto.skills.FreeLookMode.Enum} */ (reader.readEnum());
      msg.setFreeLookMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.GotoWaypointActionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.GotoWaypointActionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.GotoWaypointActionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GotoWaypointActionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWaypoint();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_vehicle_skills_waypoints_pb.Waypoint.serializeBinaryToWriter
    );
  }
  f = message.getMotionArgs();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_vehicle_skills_waypoints_pb.MotionArgs.serializeBinaryToWriter
    );
  }
  f = message.getNcpgInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_vehicle_skills_image_waypoint_pb.NcpgInfo.serializeBinaryToWriter
    );
  }
  f = message.getPreserveArScene();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getFreeLookMode();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional Waypoint waypoint = 1;
 * @return {?proto.skills.Waypoint}
 */
proto.skills.GotoWaypointActionArgs.prototype.getWaypoint = function() {
  return /** @type{?proto.skills.Waypoint} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_waypoints_pb.Waypoint, 1));
};


/**
 * @param {?proto.skills.Waypoint|undefined} value
 * @return {!proto.skills.GotoWaypointActionArgs} returns this
*/
proto.skills.GotoWaypointActionArgs.prototype.setWaypoint = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.GotoWaypointActionArgs} returns this
 */
proto.skills.GotoWaypointActionArgs.prototype.clearWaypoint = function() {
  return this.setWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.GotoWaypointActionArgs.prototype.hasWaypoint = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MotionArgs motion_args = 2;
 * @return {?proto.skills.MotionArgs}
 */
proto.skills.GotoWaypointActionArgs.prototype.getMotionArgs = function() {
  return /** @type{?proto.skills.MotionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_waypoints_pb.MotionArgs, 2));
};


/**
 * @param {?proto.skills.MotionArgs|undefined} value
 * @return {!proto.skills.GotoWaypointActionArgs} returns this
*/
proto.skills.GotoWaypointActionArgs.prototype.setMotionArgs = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.GotoWaypointActionArgs} returns this
 */
proto.skills.GotoWaypointActionArgs.prototype.clearMotionArgs = function() {
  return this.setMotionArgs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.GotoWaypointActionArgs.prototype.hasMotionArgs = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NcpgInfo ncpg_info = 3;
 * @return {?proto.skills.NcpgInfo}
 */
proto.skills.GotoWaypointActionArgs.prototype.getNcpgInfo = function() {
  return /** @type{?proto.skills.NcpgInfo} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_image_waypoint_pb.NcpgInfo, 3));
};


/**
 * @param {?proto.skills.NcpgInfo|undefined} value
 * @return {!proto.skills.GotoWaypointActionArgs} returns this
*/
proto.skills.GotoWaypointActionArgs.prototype.setNcpgInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.GotoWaypointActionArgs} returns this
 */
proto.skills.GotoWaypointActionArgs.prototype.clearNcpgInfo = function() {
  return this.setNcpgInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.GotoWaypointActionArgs.prototype.hasNcpgInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool preserve_ar_scene = 4;
 * @return {boolean}
 */
proto.skills.GotoWaypointActionArgs.prototype.getPreserveArScene = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.GotoWaypointActionArgs} returns this
 */
proto.skills.GotoWaypointActionArgs.prototype.setPreserveArScene = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional FreeLookMode.Enum free_look_mode = 5;
 * @return {!proto.skills.FreeLookMode.Enum}
 */
proto.skills.GotoWaypointActionArgs.prototype.getFreeLookMode = function() {
  return /** @type {!proto.skills.FreeLookMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.skills.FreeLookMode.Enum} value
 * @return {!proto.skills.GotoWaypointActionArgs} returns this
 */
proto.skills.GotoWaypointActionArgs.prototype.setFreeLookMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.GotoGpsWaypointResultData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.GotoGpsWaypointResultData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.GotoGpsWaypointResultData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GotoGpsWaypointResultData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.GotoGpsWaypointResultData}
 */
proto.skills.GotoGpsWaypointResultData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.GotoGpsWaypointResultData;
  return proto.skills.GotoGpsWaypointResultData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.GotoGpsWaypointResultData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.GotoGpsWaypointResultData}
 */
proto.skills.GotoGpsWaypointResultData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.GotoGpsWaypointResultData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.GotoGpsWaypointResultData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.GotoGpsWaypointResultData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GotoGpsWaypointResultData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.GotoNavWaypointResultData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.GotoNavWaypointResultData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.GotoNavWaypointResultData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GotoNavWaypointResultData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.GotoNavWaypointResultData}
 */
proto.skills.GotoNavWaypointResultData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.GotoNavWaypointResultData;
  return proto.skills.GotoNavWaypointResultData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.GotoNavWaypointResultData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.GotoNavWaypointResultData}
 */
proto.skills.GotoNavWaypointResultData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.GotoNavWaypointResultData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.GotoNavWaypointResultData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.GotoNavWaypointResultData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GotoNavWaypointResultData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.GotoWaypointResultData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.GotoWaypointResultData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.GotoWaypointResultData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GotoWaypointResultData.toObject = function(includeInstance, msg) {
  var f, obj = {
gpsSource: jspb.Message.getFieldWithDefault(msg, 1, 0),
gpsFixType: jspb.Message.getFieldWithDefault(msg, 2, 0),
timeSinceLastNominalLocalization: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
timeSinceLastNominalVisualRelocalization: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.GotoWaypointResultData}
 */
proto.skills.GotoWaypointResultData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.GotoWaypointResultData;
  return proto.skills.GotoWaypointResultData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.GotoWaypointResultData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.GotoWaypointResultData}
 */
proto.skills.GotoWaypointResultData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sensors.GpsSourcePb.Enum} */ (reader.readEnum());
      msg.setGpsSource(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGpsFixType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTimeSinceLastNominalLocalization(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTimeSinceLastNominalVisualRelocalization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.GotoWaypointResultData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.GotoWaypointResultData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.GotoWaypointResultData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GotoWaypointResultData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGpsSource();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getGpsFixType();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional sensors.GpsSourcePb.Enum gps_source = 1;
 * @return {!proto.sensors.GpsSourcePb.Enum}
 */
proto.skills.GotoWaypointResultData.prototype.getGpsSource = function() {
  return /** @type {!proto.sensors.GpsSourcePb.Enum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sensors.GpsSourcePb.Enum} value
 * @return {!proto.skills.GotoWaypointResultData} returns this
 */
proto.skills.GotoWaypointResultData.prototype.setGpsSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 gps_fix_type = 2;
 * @return {number}
 */
proto.skills.GotoWaypointResultData.prototype.getGpsFixType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.GotoWaypointResultData} returns this
 */
proto.skills.GotoWaypointResultData.prototype.setGpsFixType = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float time_since_last_nominal_localization = 3;
 * @return {number}
 */
proto.skills.GotoWaypointResultData.prototype.getTimeSinceLastNominalLocalization = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.GotoWaypointResultData} returns this
 */
proto.skills.GotoWaypointResultData.prototype.setTimeSinceLastNominalLocalization = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.skills.GotoWaypointResultData} returns this
 */
proto.skills.GotoWaypointResultData.prototype.clearTimeSinceLastNominalLocalization = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.GotoWaypointResultData.prototype.hasTimeSinceLastNominalLocalization = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional float time_since_last_nominal_visual_relocalization = 4;
 * @return {number}
 */
proto.skills.GotoWaypointResultData.prototype.getTimeSinceLastNominalVisualRelocalization = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.GotoWaypointResultData} returns this
 */
proto.skills.GotoWaypointResultData.prototype.setTimeSinceLastNominalVisualRelocalization = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.skills.GotoWaypointResultData} returns this
 */
proto.skills.GotoWaypointResultData.prototype.clearTimeSinceLastNominalVisualRelocalization = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.GotoWaypointResultData.prototype.hasTimeSinceLastNominalVisualRelocalization = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.GotoGpsWaypointActionStateData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.GotoGpsWaypointActionStateData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.GotoGpsWaypointActionStateData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GotoGpsWaypointActionStateData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.GotoGpsWaypointActionStateData}
 */
proto.skills.GotoGpsWaypointActionStateData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.GotoGpsWaypointActionStateData;
  return proto.skills.GotoGpsWaypointActionStateData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.GotoGpsWaypointActionStateData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.GotoGpsWaypointActionStateData}
 */
proto.skills.GotoGpsWaypointActionStateData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.GotoGpsWaypointActionStateData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.GotoGpsWaypointActionStateData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.GotoGpsWaypointActionStateData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GotoGpsWaypointActionStateData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.GotoNavWaypointActionStateData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.GotoNavWaypointActionStateData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.GotoNavWaypointActionStateData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GotoNavWaypointActionStateData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.GotoNavWaypointActionStateData}
 */
proto.skills.GotoNavWaypointActionStateData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.GotoNavWaypointActionStateData;
  return proto.skills.GotoNavWaypointActionStateData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.GotoNavWaypointActionStateData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.GotoNavWaypointActionStateData}
 */
proto.skills.GotoNavWaypointActionStateData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.GotoNavWaypointActionStateData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.GotoNavWaypointActionStateData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.GotoNavWaypointActionStateData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GotoNavWaypointActionStateData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.GotoWaypointActionStateData.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.GotoWaypointActionStateData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.GotoWaypointActionStateData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.GotoWaypointActionStateData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GotoWaypointActionStateData.toObject = function(includeInstance, msg) {
  var f, obj = {
runningGpsInitialization: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
intermediateWaypointsList: jspb.Message.toObjectList(msg.getIntermediateWaypointsList(),
    pbtypes_vehicle_skills_waypoints_pb.Waypoint.toObject, includeInstance),
intermediateWaypointsDoneComputing: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.GotoWaypointActionStateData}
 */
proto.skills.GotoWaypointActionStateData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.GotoWaypointActionStateData;
  return proto.skills.GotoWaypointActionStateData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.GotoWaypointActionStateData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.GotoWaypointActionStateData}
 */
proto.skills.GotoWaypointActionStateData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRunningGpsInitialization(value);
      break;
    case 2:
      var value = new pbtypes_vehicle_skills_waypoints_pb.Waypoint;
      reader.readMessage(value,pbtypes_vehicle_skills_waypoints_pb.Waypoint.deserializeBinaryFromReader);
      msg.addIntermediateWaypoints(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIntermediateWaypointsDoneComputing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.GotoWaypointActionStateData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.GotoWaypointActionStateData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.GotoWaypointActionStateData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GotoWaypointActionStateData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunningGpsInitialization();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIntermediateWaypointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      pbtypes_vehicle_skills_waypoints_pb.Waypoint.serializeBinaryToWriter
    );
  }
  f = message.getIntermediateWaypointsDoneComputing();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool running_gps_initialization = 1;
 * @return {boolean}
 */
proto.skills.GotoWaypointActionStateData.prototype.getRunningGpsInitialization = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.GotoWaypointActionStateData} returns this
 */
proto.skills.GotoWaypointActionStateData.prototype.setRunningGpsInitialization = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated Waypoint intermediate_waypoints = 2;
 * @return {!Array<!proto.skills.Waypoint>}
 */
proto.skills.GotoWaypointActionStateData.prototype.getIntermediateWaypointsList = function() {
  return /** @type{!Array<!proto.skills.Waypoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_vehicle_skills_waypoints_pb.Waypoint, 2));
};


/**
 * @param {!Array<!proto.skills.Waypoint>} value
 * @return {!proto.skills.GotoWaypointActionStateData} returns this
*/
proto.skills.GotoWaypointActionStateData.prototype.setIntermediateWaypointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.skills.Waypoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.Waypoint}
 */
proto.skills.GotoWaypointActionStateData.prototype.addIntermediateWaypoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.skills.Waypoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.GotoWaypointActionStateData} returns this
 */
proto.skills.GotoWaypointActionStateData.prototype.clearIntermediateWaypointsList = function() {
  return this.setIntermediateWaypointsList([]);
};


/**
 * optional bool intermediate_waypoints_done_computing = 3;
 * @return {boolean}
 */
proto.skills.GotoWaypointActionStateData.prototype.getIntermediateWaypointsDoneComputing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.GotoWaypointActionStateData} returns this
 */
proto.skills.GotoWaypointActionStateData.prototype.setIntermediateWaypointsDoneComputing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


goog.object.extend(exports, proto.skills);
