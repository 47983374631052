// source: pbtypes/vehicle/skills/actions/mission_planner_service_control/mission_planner_service_control.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.skills.MissionPlannerServiceControlActionArgs', null, global);
goog.exportSymbol('proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest', null, global);
goog.exportSymbol('proto.skills.MissionPlannerServiceControlActionArgs.ControlCase', null, global);
goog.exportSymbol('proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest', null, global);
goog.exportSymbol('proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest', null, global);
goog.exportSymbol('proto.skills.MissionPlannerServiceControlActionStateData', null, global);
goog.exportSymbol('proto.skills.MissionPlannerServiceControlResultData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.MissionPlannerServiceControlActionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.skills.MissionPlannerServiceControlActionArgs.oneofGroups_);
};
goog.inherits(proto.skills.MissionPlannerServiceControlActionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.MissionPlannerServiceControlActionArgs.displayName = 'proto.skills.MissionPlannerServiceControlActionArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest.displayName = 'proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest.displayName = 'proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest.displayName = 'proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.MissionPlannerServiceControlResultData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.MissionPlannerServiceControlResultData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.MissionPlannerServiceControlResultData.displayName = 'proto.skills.MissionPlannerServiceControlResultData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.MissionPlannerServiceControlActionStateData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.MissionPlannerServiceControlActionStateData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.MissionPlannerServiceControlActionStateData.displayName = 'proto.skills.MissionPlannerServiceControlActionStateData';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.skills.MissionPlannerServiceControlActionArgs.oneofGroups_ = [[2,3,4]];

/**
 * @enum {number}
 */
proto.skills.MissionPlannerServiceControlActionArgs.ControlCase = {
  CONTROL_NOT_SET: 0,
  START_MISSION_PLANNING_REQUEST: 2,
  GET_WAYPOINT_FOR_CURRENT_POSE_REQUEST: 3,
  CANCEL_MISSION_PLANNING_SESSION_REQUEST: 4
};

/**
 * @return {proto.skills.MissionPlannerServiceControlActionArgs.ControlCase}
 */
proto.skills.MissionPlannerServiceControlActionArgs.prototype.getControlCase = function() {
  return /** @type {proto.skills.MissionPlannerServiceControlActionArgs.ControlCase} */(jspb.Message.computeOneofCase(this, proto.skills.MissionPlannerServiceControlActionArgs.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.MissionPlannerServiceControlActionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.MissionPlannerServiceControlActionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.MissionPlannerServiceControlActionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MissionPlannerServiceControlActionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
timeoutS: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
startMissionPlanningRequest: (f = msg.getStartMissionPlanningRequest()) && proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest.toObject(includeInstance, f),
getWaypointForCurrentPoseRequest: (f = msg.getGetWaypointForCurrentPoseRequest()) && proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest.toObject(includeInstance, f),
cancelMissionPlanningSessionRequest: (f = msg.getCancelMissionPlanningSessionRequest()) && proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.MissionPlannerServiceControlActionArgs}
 */
proto.skills.MissionPlannerServiceControlActionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.MissionPlannerServiceControlActionArgs;
  return proto.skills.MissionPlannerServiceControlActionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.MissionPlannerServiceControlActionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.MissionPlannerServiceControlActionArgs}
 */
proto.skills.MissionPlannerServiceControlActionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTimeoutS(value);
      break;
    case 2:
      var value = new proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest;
      reader.readMessage(value,proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest.deserializeBinaryFromReader);
      msg.setStartMissionPlanningRequest(value);
      break;
    case 3:
      var value = new proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest;
      reader.readMessage(value,proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest.deserializeBinaryFromReader);
      msg.setGetWaypointForCurrentPoseRequest(value);
      break;
    case 4:
      var value = new proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest;
      reader.readMessage(value,proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest.deserializeBinaryFromReader);
      msg.setCancelMissionPlanningSessionRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.MissionPlannerServiceControlActionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.MissionPlannerServiceControlActionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.MissionPlannerServiceControlActionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MissionPlannerServiceControlActionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeoutS();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getStartMissionPlanningRequest();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetWaypointForCurrentPoseRequest();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest.serializeBinaryToWriter
    );
  }
  f = message.getCancelMissionPlanningSessionRequest();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
json: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest}
 */
proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest;
  return proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest}
 */
proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setJson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJson();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string json = 1;
 * @return {string}
 */
proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest.prototype.getJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest} returns this
 */
proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest.prototype.setJson = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
json: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest}
 */
proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest;
  return proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest}
 */
proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setJson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJson();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string json = 1;
 * @return {string}
 */
proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest.prototype.getJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest} returns this
 */
proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest.prototype.setJson = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
json: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest}
 */
proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest;
  return proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest}
 */
proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setJson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJson();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string json = 1;
 * @return {string}
 */
proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest.prototype.getJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest} returns this
 */
proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest.prototype.setJson = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float timeout_s = 1;
 * @return {number}
 */
proto.skills.MissionPlannerServiceControlActionArgs.prototype.getTimeoutS = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MissionPlannerServiceControlActionArgs} returns this
 */
proto.skills.MissionPlannerServiceControlActionArgs.prototype.setTimeoutS = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional StartMissionPlanningRequest start_mission_planning_request = 2;
 * @return {?proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest}
 */
proto.skills.MissionPlannerServiceControlActionArgs.prototype.getStartMissionPlanningRequest = function() {
  return /** @type{?proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest} */ (
    jspb.Message.getWrapperField(this, proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest, 2));
};


/**
 * @param {?proto.skills.MissionPlannerServiceControlActionArgs.StartMissionPlanningRequest|undefined} value
 * @return {!proto.skills.MissionPlannerServiceControlActionArgs} returns this
*/
proto.skills.MissionPlannerServiceControlActionArgs.prototype.setStartMissionPlanningRequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.skills.MissionPlannerServiceControlActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.MissionPlannerServiceControlActionArgs} returns this
 */
proto.skills.MissionPlannerServiceControlActionArgs.prototype.clearStartMissionPlanningRequest = function() {
  return this.setStartMissionPlanningRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.MissionPlannerServiceControlActionArgs.prototype.hasStartMissionPlanningRequest = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional GetWaypointForCurrentPoseRequest get_waypoint_for_current_pose_request = 3;
 * @return {?proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest}
 */
proto.skills.MissionPlannerServiceControlActionArgs.prototype.getGetWaypointForCurrentPoseRequest = function() {
  return /** @type{?proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest} */ (
    jspb.Message.getWrapperField(this, proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest, 3));
};


/**
 * @param {?proto.skills.MissionPlannerServiceControlActionArgs.GetWaypointForCurrentPoseRequest|undefined} value
 * @return {!proto.skills.MissionPlannerServiceControlActionArgs} returns this
*/
proto.skills.MissionPlannerServiceControlActionArgs.prototype.setGetWaypointForCurrentPoseRequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.skills.MissionPlannerServiceControlActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.MissionPlannerServiceControlActionArgs} returns this
 */
proto.skills.MissionPlannerServiceControlActionArgs.prototype.clearGetWaypointForCurrentPoseRequest = function() {
  return this.setGetWaypointForCurrentPoseRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.MissionPlannerServiceControlActionArgs.prototype.hasGetWaypointForCurrentPoseRequest = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CancelMissionPlanningSessionRequest cancel_mission_planning_session_request = 4;
 * @return {?proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest}
 */
proto.skills.MissionPlannerServiceControlActionArgs.prototype.getCancelMissionPlanningSessionRequest = function() {
  return /** @type{?proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest} */ (
    jspb.Message.getWrapperField(this, proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest, 4));
};


/**
 * @param {?proto.skills.MissionPlannerServiceControlActionArgs.CancelMissionPlanningSessionRequest|undefined} value
 * @return {!proto.skills.MissionPlannerServiceControlActionArgs} returns this
*/
proto.skills.MissionPlannerServiceControlActionArgs.prototype.setCancelMissionPlanningSessionRequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.skills.MissionPlannerServiceControlActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.MissionPlannerServiceControlActionArgs} returns this
 */
proto.skills.MissionPlannerServiceControlActionArgs.prototype.clearCancelMissionPlanningSessionRequest = function() {
  return this.setCancelMissionPlanningSessionRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.MissionPlannerServiceControlActionArgs.prototype.hasCancelMissionPlanningSessionRequest = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.MissionPlannerServiceControlResultData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.MissionPlannerServiceControlResultData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.MissionPlannerServiceControlResultData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MissionPlannerServiceControlResultData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.MissionPlannerServiceControlResultData}
 */
proto.skills.MissionPlannerServiceControlResultData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.MissionPlannerServiceControlResultData;
  return proto.skills.MissionPlannerServiceControlResultData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.MissionPlannerServiceControlResultData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.MissionPlannerServiceControlResultData}
 */
proto.skills.MissionPlannerServiceControlResultData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.MissionPlannerServiceControlResultData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.MissionPlannerServiceControlResultData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.MissionPlannerServiceControlResultData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MissionPlannerServiceControlResultData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.MissionPlannerServiceControlActionStateData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.MissionPlannerServiceControlActionStateData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.MissionPlannerServiceControlActionStateData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MissionPlannerServiceControlActionStateData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.MissionPlannerServiceControlActionStateData}
 */
proto.skills.MissionPlannerServiceControlActionStateData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.MissionPlannerServiceControlActionStateData;
  return proto.skills.MissionPlannerServiceControlActionStateData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.MissionPlannerServiceControlActionStateData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.MissionPlannerServiceControlActionStateData}
 */
proto.skills.MissionPlannerServiceControlActionStateData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.MissionPlannerServiceControlActionStateData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.MissionPlannerServiceControlActionStateData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.MissionPlannerServiceControlActionStateData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MissionPlannerServiceControlActionStateData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.skills);
