/* Tuples. */
/** Read-only array of length 1. Specify all item types or only one for a homogeneous tuple.
 * Mutating is a type error. */
export type Tuple1<T> = readonly [T];
/** Read-only array of length 2. Specify all item types or only one for a homogeneous tuple.
 * Mutating is a type error. */
export type Tuple2<T1, T2 = T1> = readonly [T1, T2];
/** Read-only array of length 3. Specify all item types or only one for a homogeneous tuple.
 * Mutating is a type error. */
export type Tuple3<T1, T2 = T1, T3 = T1> = readonly [T1, T2, T3];
/** Read-only array of length 4. Specify all item types or only one for a homogeneous tuple.
 * Mutating is a type error. */
export type Tuple4<T1, T2 = T1, T3 = T1, T4 = T1> = readonly [T1, T2, T3, T4];

/** Recursively set all properties to optional. */
export type DeepPartial<T> = {
  [K in keyof T]?: T[K] extends object ? DeepPartial<T[K]> : T[K];
};

export type PickCommonKeysDeep<T, U> = {
  [K in Extract<keyof T, keyof U>]: NonNullable<T[K]> extends object
    ? NonNullable<U[K]> extends object
      ? PickCommonKeysDeep<NonNullable<T[K]>, NonNullable<U[K]>>
      : T[K]
    : T[K];
};
/**
 * Union T with `null` and `undefined`. Inverse of the built-in `NonNullable`.
 */
export type Nullable<T> = T | null | undefined;

/**
 * Remove `readonly` modifiers from T.
 */
export type Mutable<T> = {
  -readonly [P in keyof T]: T[P] extends ReadonlyArray<infer U> ? Mutable<U>[] : Mutable<T[P]>;
};

/**
 * Given an array, infer the type of element in it.
 */
export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

const emptySymbol = Symbol("EmptyObject type");
export type EmptyObject = { [emptySymbol]?: never };

// Create an object type that replaces properties from the first type argument with properties from
// the second.
// For example, Overwrite<{ foo: string; bar: number }, { bar: boolean }> will produce
// { foo: string; bar: boolean }
export type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;

export type Prettify<T> = T extends object ? { [K in keyof T]: T[K] } : T;

export type ValueType<T extends object> = T[keyof T];
