// source: pbtypes/gen/scan_planner/scan_photo.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_body_trans_pb = require('../../../pbtypes/gen/body/trans_pb.js');
goog.object.extend(proto, pbtypes_gen_body_trans_pb);
var pbtypes_gen_scan_planner_scan_photo_status_pb = require('../../../pbtypes/gen/scan_planner/scan_photo_status_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_scan_photo_status_pb);
var pbtypes_gen_scan_planner_scan_waypoint_pb = require('../../../pbtypes/gen/scan_planner/scan_waypoint_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_scan_waypoint_pb);
var pbtypes_gen_scan_planner_trajectory_action_planner_photo_status_pb = require('../../../pbtypes/gen/scan_planner/trajectory_action_planner_photo_status_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_trajectory_action_planner_photo_status_pb);
var pbtypes_gen_scan_planner_trajectory_action_planner_stop_at_waypoint_reason_pb = require('../../../pbtypes/gen/scan_planner/trajectory_action_planner_stop_at_waypoint_reason_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_trajectory_action_planner_stop_at_waypoint_reason_pb);
var pbtypes_gen_surface_scan_inspection_type_pb = require('../../../pbtypes/gen/surface_scan/inspection_type_pb.js');
goog.object.extend(proto, pbtypes_gen_surface_scan_inspection_type_pb);
var pbtypes_gen_surface_scan_photo_mode_pb = require('../../../pbtypes/gen/surface_scan/photo_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_surface_scan_photo_mode_pb);
var pbtypes_gen_user_camera_awb_mode_pb = require('../../../pbtypes/gen/user_camera/awb_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_awb_mode_pb);
var pbtypes_gen_user_camera_exposure_mode_pb = require('../../../pbtypes/gen/user_camera/exposure_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_exposure_mode_pb);
var pbtypes_gen_user_camera_recording_mode_pb = require('../../../pbtypes/gen/user_camera/recording_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_recording_mode_pb);
goog.exportSymbol('proto.scan_planner.ScanPhoto', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scan_planner.ScanPhoto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scan_planner.ScanPhoto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scan_planner.ScanPhoto.displayName = 'proto.scan_planner.ScanPhoto';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scan_planner.ScanPhoto.prototype.toObject = function(opt_includeInstance) {
  return proto.scan_planner.ScanPhoto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scan_planner.ScanPhoto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scan_planner.ScanPhoto.toObject = function(includeInstance, msg) {
  var f, obj = {
waypoint: (f = msg.getWaypoint()) && pbtypes_gen_scan_planner_scan_waypoint_pb.ScanWaypoint.toObject(includeInstance, f),
status: jspb.Message.getFieldWithDefault(msg, 2, 0),
path: jspb.Message.getFieldWithDefault(msg, 3, ""),
mediaId: jspb.Message.getFieldWithDefault(msg, 12, ""),
dngPath: jspb.Message.getFieldWithDefault(msg, 11, ""),
dngMediaId: jspb.Message.getFieldWithDefault(msg, 14, ""),
photoMode: jspb.Message.getFieldWithDefault(msg, 15, 0),
utime: jspb.Message.getFieldWithDefault(msg, 4, 0),
uclock: jspb.Message.getFieldWithDefault(msg, 5, 0),
awbMode: jspb.Message.getFieldWithDefault(msg, 6, 0),
exposureMode: jspb.Message.getFieldWithDefault(msg, 7, 0),
recordingMode: jspb.Message.getFieldWithDefault(msg, 13, 0),
subjectMeanInvRange: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
globalMapTPhoto: (f = msg.getGlobalMapTPhoto()) && pbtypes_gen_body_trans_pb.TransPb.toObject(includeInstance, f),
inspectionType: jspb.Message.getFieldWithDefault(msg, 10, 0),
trajectoryActionPlannerPhotoStatus: jspb.Message.getFieldWithDefault(msg, 16, 0),
gimbalImuTWaypointRdf: (f = msg.getGimbalImuTWaypointRdf()) && pbtypes_gen_body_trans_pb.TransPb.toObject(includeInstance, f),
gimbalImuStartExpositionTGimbalImuEndExpositionRdf: (f = msg.getGimbalImuStartExpositionTGimbalImuEndExpositionRdf()) && pbtypes_gen_body_trans_pb.TransPb.toObject(includeInstance, f),
exposureTimeS: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
captureSurfacePixelsTravelled: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
gimbalImuLinearVelocityLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0),
gimbalImuAngularVelocityLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
stopAtWaypoint: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
stopAtWaypointReason: jspb.Message.getFieldWithDefault(msg, 32, 0),
minVehicleToGimbalPitch: jspb.Message.getFloatingPointFieldWithDefault(msg, 24, 0.0),
maxGimbalRoll: jspb.Message.getFloatingPointFieldWithDefault(msg, 25, 0.0),
maxGimbalRollRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 26, 0.0),
ispPhotoCaptureUtime: jspb.Message.getFieldWithDefault(msg, 27, 0),
ispInProgressExposedUtime: jspb.Message.getFieldWithDefault(msg, 28, 0),
ispReadyForNextUtime: jspb.Message.getFieldWithDefault(msg, 29, 0),
ispCaptureSuccessfulUtime: jspb.Message.getFieldWithDefault(msg, 30, 0),
ispCaptureFailedUtime: jspb.Message.getFieldWithDefault(msg, 31, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scan_planner.ScanPhoto}
 */
proto.scan_planner.ScanPhoto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scan_planner.ScanPhoto;
  return proto.scan_planner.ScanPhoto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scan_planner.ScanPhoto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scan_planner.ScanPhoto}
 */
proto.scan_planner.ScanPhoto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_gen_scan_planner_scan_waypoint_pb.ScanWaypoint;
      reader.readMessage(value,pbtypes_gen_scan_planner_scan_waypoint_pb.ScanWaypoint.deserializeBinaryFromReader);
      msg.setWaypoint(value);
      break;
    case 2:
      var value = /** @type {!proto.scan_planner.ScanPhotoStatus.Enum} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setMediaId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDngPath(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setDngMediaId(value);
      break;
    case 15:
      var value = /** @type {!proto.surface_scan.PhotoMode.Enum} */ (reader.readEnum());
      msg.setPhotoMode(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setUtime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setUclock(value);
      break;
    case 6:
      var value = /** @type {!proto.user_camera.AwbMode.Enum} */ (reader.readEnum());
      msg.setAwbMode(value);
      break;
    case 7:
      var value = /** @type {!proto.user_camera.ExposureMode.Enum} */ (reader.readEnum());
      msg.setExposureMode(value);
      break;
    case 13:
      var value = /** @type {!proto.user_camera.RecordingMode.Enum} */ (reader.readEnum());
      msg.setRecordingMode(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSubjectMeanInvRange(value);
      break;
    case 9:
      var value = new pbtypes_gen_body_trans_pb.TransPb;
      reader.readMessage(value,pbtypes_gen_body_trans_pb.TransPb.deserializeBinaryFromReader);
      msg.setGlobalMapTPhoto(value);
      break;
    case 10:
      var value = /** @type {!proto.surface_scan.InspectionType.Enum} */ (reader.readEnum());
      msg.setInspectionType(value);
      break;
    case 16:
      var value = /** @type {!proto.scan_planner.TrajectoryActionPlannerPhotoStatus.Enum} */ (reader.readEnum());
      msg.setTrajectoryActionPlannerPhotoStatus(value);
      break;
    case 17:
      var value = new pbtypes_gen_body_trans_pb.TransPb;
      reader.readMessage(value,pbtypes_gen_body_trans_pb.TransPb.deserializeBinaryFromReader);
      msg.setGimbalImuTWaypointRdf(value);
      break;
    case 18:
      var value = new pbtypes_gen_body_trans_pb.TransPb;
      reader.readMessage(value,pbtypes_gen_body_trans_pb.TransPb.deserializeBinaryFromReader);
      msg.setGimbalImuStartExpositionTGimbalImuEndExpositionRdf(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setExposureTimeS(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCaptureSurfacePixelsTravelled(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGimbalImuLinearVelocityLimit(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGimbalImuAngularVelocityLimit(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStopAtWaypoint(value);
      break;
    case 32:
      var value = /** @type {!proto.scan_planner.TrajectoryActionPlannerStopAtWaypointReason.Enum} */ (reader.readEnum());
      msg.setStopAtWaypointReason(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinVehicleToGimbalPitch(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxGimbalRoll(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxGimbalRollRate(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setIspPhotoCaptureUtime(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setIspInProgressExposedUtime(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setIspReadyForNextUtime(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setIspCaptureSuccessfulUtime(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setIspCaptureFailedUtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scan_planner.ScanPhoto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scan_planner.ScanPhoto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scan_planner.ScanPhoto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scan_planner.ScanPhoto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWaypoint();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_gen_scan_planner_scan_waypoint_pb.ScanWaypoint.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMediaId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getDngPath();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDngMediaId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getPhotoMode();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getUtime();
  if (f !== 0) {
    writer.writeSint64(
      4,
      f
    );
  }
  f = message.getUclock();
  if (f !== 0) {
    writer.writeSint64(
      5,
      f
    );
  }
  f = message.getAwbMode();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getExposureMode();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getRecordingMode();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getSubjectMeanInvRange();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getGlobalMapTPhoto();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      pbtypes_gen_body_trans_pb.TransPb.serializeBinaryToWriter
    );
  }
  f = message.getInspectionType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getTrajectoryActionPlannerPhotoStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getGimbalImuTWaypointRdf();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      pbtypes_gen_body_trans_pb.TransPb.serializeBinaryToWriter
    );
  }
  f = message.getGimbalImuStartExpositionTGimbalImuEndExpositionRdf();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      pbtypes_gen_body_trans_pb.TransPb.serializeBinaryToWriter
    );
  }
  f = message.getExposureTimeS();
  if (f !== 0.0) {
    writer.writeDouble(
      19,
      f
    );
  }
  f = message.getCaptureSurfacePixelsTravelled();
  if (f !== 0.0) {
    writer.writeDouble(
      20,
      f
    );
  }
  f = message.getGimbalImuLinearVelocityLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      21,
      f
    );
  }
  f = message.getGimbalImuAngularVelocityLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      22,
      f
    );
  }
  f = message.getStopAtWaypoint();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getStopAtWaypointReason();
  if (f !== 0.0) {
    writer.writeEnum(
      32,
      f
    );
  }
  f = message.getMinVehicleToGimbalPitch();
  if (f !== 0.0) {
    writer.writeDouble(
      24,
      f
    );
  }
  f = message.getMaxGimbalRoll();
  if (f !== 0.0) {
    writer.writeDouble(
      25,
      f
    );
  }
  f = message.getMaxGimbalRollRate();
  if (f !== 0.0) {
    writer.writeDouble(
      26,
      f
    );
  }
  f = message.getIspPhotoCaptureUtime();
  if (f !== 0) {
    writer.writeSint64(
      27,
      f
    );
  }
  f = message.getIspInProgressExposedUtime();
  if (f !== 0) {
    writer.writeSint64(
      28,
      f
    );
  }
  f = message.getIspReadyForNextUtime();
  if (f !== 0) {
    writer.writeSint64(
      29,
      f
    );
  }
  f = message.getIspCaptureSuccessfulUtime();
  if (f !== 0) {
    writer.writeSint64(
      30,
      f
    );
  }
  f = message.getIspCaptureFailedUtime();
  if (f !== 0) {
    writer.writeSint64(
      31,
      f
    );
  }
};


/**
 * optional ScanWaypoint waypoint = 1;
 * @return {?proto.scan_planner.ScanWaypoint}
 */
proto.scan_planner.ScanPhoto.prototype.getWaypoint = function() {
  return /** @type{?proto.scan_planner.ScanWaypoint} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_scan_planner_scan_waypoint_pb.ScanWaypoint, 1));
};


/**
 * @param {?proto.scan_planner.ScanWaypoint|undefined} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
*/
proto.scan_planner.ScanPhoto.prototype.setWaypoint = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.clearWaypoint = function() {
  return this.setWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_planner.ScanPhoto.prototype.hasWaypoint = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ScanPhotoStatus.Enum status = 2;
 * @return {!proto.scan_planner.ScanPhotoStatus.Enum}
 */
proto.scan_planner.ScanPhoto.prototype.getStatus = function() {
  return /** @type {!proto.scan_planner.ScanPhotoStatus.Enum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.scan_planner.ScanPhotoStatus.Enum} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string path = 3;
 * @return {string}
 */
proto.scan_planner.ScanPhoto.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string media_id = 12;
 * @return {string}
 */
proto.scan_planner.ScanPhoto.prototype.getMediaId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.setMediaId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string dng_path = 11;
 * @return {string}
 */
proto.scan_planner.ScanPhoto.prototype.getDngPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.setDngPath = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string dng_media_id = 14;
 * @return {string}
 */
proto.scan_planner.ScanPhoto.prototype.getDngMediaId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.setDngMediaId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional surface_scan.PhotoMode.Enum photo_mode = 15;
 * @return {!proto.surface_scan.PhotoMode.Enum}
 */
proto.scan_planner.ScanPhoto.prototype.getPhotoMode = function() {
  return /** @type {!proto.surface_scan.PhotoMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.surface_scan.PhotoMode.Enum} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.setPhotoMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional sint64 utime = 4;
 * @return {number}
 */
proto.scan_planner.ScanPhoto.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional sint64 uclock = 5;
 * @return {number}
 */
proto.scan_planner.ScanPhoto.prototype.getUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.setUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional user_camera.AwbMode.Enum awb_mode = 6;
 * @return {!proto.user_camera.AwbMode.Enum}
 */
proto.scan_planner.ScanPhoto.prototype.getAwbMode = function() {
  return /** @type {!proto.user_camera.AwbMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.user_camera.AwbMode.Enum} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.setAwbMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional user_camera.ExposureMode.Enum exposure_mode = 7;
 * @return {!proto.user_camera.ExposureMode.Enum}
 */
proto.scan_planner.ScanPhoto.prototype.getExposureMode = function() {
  return /** @type {!proto.user_camera.ExposureMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.user_camera.ExposureMode.Enum} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.setExposureMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional user_camera.RecordingMode.Enum recording_mode = 13;
 * @return {!proto.user_camera.RecordingMode.Enum}
 */
proto.scan_planner.ScanPhoto.prototype.getRecordingMode = function() {
  return /** @type {!proto.user_camera.RecordingMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.user_camera.RecordingMode.Enum} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.setRecordingMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional float subject_mean_inv_range = 8;
 * @return {number}
 */
proto.scan_planner.ScanPhoto.prototype.getSubjectMeanInvRange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.setSubjectMeanInvRange = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional body.TransPb global_map_t_photo = 9;
 * @return {?proto.body.TransPb}
 */
proto.scan_planner.ScanPhoto.prototype.getGlobalMapTPhoto = function() {
  return /** @type{?proto.body.TransPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_body_trans_pb.TransPb, 9));
};


/**
 * @param {?proto.body.TransPb|undefined} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
*/
proto.scan_planner.ScanPhoto.prototype.setGlobalMapTPhoto = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.clearGlobalMapTPhoto = function() {
  return this.setGlobalMapTPhoto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_planner.ScanPhoto.prototype.hasGlobalMapTPhoto = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional surface_scan.InspectionType.Enum inspection_type = 10;
 * @return {!proto.surface_scan.InspectionType.Enum}
 */
proto.scan_planner.ScanPhoto.prototype.getInspectionType = function() {
  return /** @type {!proto.surface_scan.InspectionType.Enum} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.surface_scan.InspectionType.Enum} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.setInspectionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional TrajectoryActionPlannerPhotoStatus.Enum trajectory_action_planner_photo_status = 16;
 * @return {!proto.scan_planner.TrajectoryActionPlannerPhotoStatus.Enum}
 */
proto.scan_planner.ScanPhoto.prototype.getTrajectoryActionPlannerPhotoStatus = function() {
  return /** @type {!proto.scan_planner.TrajectoryActionPlannerPhotoStatus.Enum} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.scan_planner.TrajectoryActionPlannerPhotoStatus.Enum} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.setTrajectoryActionPlannerPhotoStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional body.TransPb gimbal_imu_t_waypoint_rdf = 17;
 * @return {?proto.body.TransPb}
 */
proto.scan_planner.ScanPhoto.prototype.getGimbalImuTWaypointRdf = function() {
  return /** @type{?proto.body.TransPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_body_trans_pb.TransPb, 17));
};


/**
 * @param {?proto.body.TransPb|undefined} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
*/
proto.scan_planner.ScanPhoto.prototype.setGimbalImuTWaypointRdf = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.clearGimbalImuTWaypointRdf = function() {
  return this.setGimbalImuTWaypointRdf(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_planner.ScanPhoto.prototype.hasGimbalImuTWaypointRdf = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional body.TransPb gimbal_imu_start_exposition_t_gimbal_imu_end_exposition_rdf = 18;
 * @return {?proto.body.TransPb}
 */
proto.scan_planner.ScanPhoto.prototype.getGimbalImuStartExpositionTGimbalImuEndExpositionRdf = function() {
  return /** @type{?proto.body.TransPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_body_trans_pb.TransPb, 18));
};


/**
 * @param {?proto.body.TransPb|undefined} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
*/
proto.scan_planner.ScanPhoto.prototype.setGimbalImuStartExpositionTGimbalImuEndExpositionRdf = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.clearGimbalImuStartExpositionTGimbalImuEndExpositionRdf = function() {
  return this.setGimbalImuStartExpositionTGimbalImuEndExpositionRdf(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_planner.ScanPhoto.prototype.hasGimbalImuStartExpositionTGimbalImuEndExpositionRdf = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional double exposure_time_s = 19;
 * @return {number}
 */
proto.scan_planner.ScanPhoto.prototype.getExposureTimeS = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.setExposureTimeS = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional double capture_surface_pixels_travelled = 20;
 * @return {number}
 */
proto.scan_planner.ScanPhoto.prototype.getCaptureSurfacePixelsTravelled = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.setCaptureSurfacePixelsTravelled = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * optional double gimbal_imu_linear_velocity_limit = 21;
 * @return {number}
 */
proto.scan_planner.ScanPhoto.prototype.getGimbalImuLinearVelocityLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.setGimbalImuLinearVelocityLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};


/**
 * optional double gimbal_imu_angular_velocity_limit = 22;
 * @return {number}
 */
proto.scan_planner.ScanPhoto.prototype.getGimbalImuAngularVelocityLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.setGimbalImuAngularVelocityLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional bool stop_at_waypoint = 23;
 * @return {boolean}
 */
proto.scan_planner.ScanPhoto.prototype.getStopAtWaypoint = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.setStopAtWaypoint = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional TrajectoryActionPlannerStopAtWaypointReason.Enum stop_at_waypoint_reason = 32;
 * @return {!proto.scan_planner.TrajectoryActionPlannerStopAtWaypointReason.Enum}
 */
proto.scan_planner.ScanPhoto.prototype.getStopAtWaypointReason = function() {
  return /** @type {!proto.scan_planner.TrajectoryActionPlannerStopAtWaypointReason.Enum} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {!proto.scan_planner.TrajectoryActionPlannerStopAtWaypointReason.Enum} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.setStopAtWaypointReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 32, value);
};


/**
 * optional double min_vehicle_to_gimbal_pitch = 24;
 * @return {number}
 */
proto.scan_planner.ScanPhoto.prototype.getMinVehicleToGimbalPitch = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 24, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.setMinVehicleToGimbalPitch = function(value) {
  return jspb.Message.setProto3FloatField(this, 24, value);
};


/**
 * optional double max_gimbal_roll = 25;
 * @return {number}
 */
proto.scan_planner.ScanPhoto.prototype.getMaxGimbalRoll = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 25, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.setMaxGimbalRoll = function(value) {
  return jspb.Message.setProto3FloatField(this, 25, value);
};


/**
 * optional double max_gimbal_roll_rate = 26;
 * @return {number}
 */
proto.scan_planner.ScanPhoto.prototype.getMaxGimbalRollRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 26, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.setMaxGimbalRollRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 26, value);
};


/**
 * optional sint64 isp_photo_capture_utime = 27;
 * @return {number}
 */
proto.scan_planner.ScanPhoto.prototype.getIspPhotoCaptureUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.setIspPhotoCaptureUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional sint64 isp_in_progress_exposed_utime = 28;
 * @return {number}
 */
proto.scan_planner.ScanPhoto.prototype.getIspInProgressExposedUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.setIspInProgressExposedUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * optional sint64 isp_ready_for_next_utime = 29;
 * @return {number}
 */
proto.scan_planner.ScanPhoto.prototype.getIspReadyForNextUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {number} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.setIspReadyForNextUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 29, value);
};


/**
 * optional sint64 isp_capture_successful_utime = 30;
 * @return {number}
 */
proto.scan_planner.ScanPhoto.prototype.getIspCaptureSuccessfulUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.setIspCaptureSuccessfulUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * optional sint64 isp_capture_failed_utime = 31;
 * @return {number}
 */
proto.scan_planner.ScanPhoto.prototype.getIspCaptureFailedUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/**
 * @param {number} value
 * @return {!proto.scan_planner.ScanPhoto} returns this
 */
proto.scan_planner.ScanPhoto.prototype.setIspCaptureFailedUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 31, value);
};


goog.object.extend(exports, proto.scan_planner);
