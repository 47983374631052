// source: pbtypes/dock/weather_testing/weather_testing.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.weather_testing.RainRigCommand', null, global);
goog.exportSymbol('proto.weather_testing.RainRigCommand.RainRigCommandType', null, global);
goog.exportSymbol('proto.weather_testing.RainRigRain', null, global);
goog.exportSymbol('proto.weather_testing.WeatherTestingConditionType', null, global);
goog.exportSymbol('proto.weather_testing.WindWallCommand', null, global);
goog.exportSymbol('proto.weather_testing.WindWallCommand.WindWallCommandType', null, global);
goog.exportSymbol('proto.weather_testing.WindWallWind', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.weather_testing.WindWallCommand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.weather_testing.WindWallCommand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.weather_testing.WindWallCommand.displayName = 'proto.weather_testing.WindWallCommand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.weather_testing.WindWallWind = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.weather_testing.WindWallWind, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.weather_testing.WindWallWind.displayName = 'proto.weather_testing.WindWallWind';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.weather_testing.RainRigCommand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.weather_testing.RainRigCommand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.weather_testing.RainRigCommand.displayName = 'proto.weather_testing.RainRigCommand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.weather_testing.RainRigRain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.weather_testing.RainRigRain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.weather_testing.RainRigRain.displayName = 'proto.weather_testing.RainRigRain';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.weather_testing.WindWallCommand.prototype.toObject = function(opt_includeInstance) {
  return proto.weather_testing.WindWallCommand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.weather_testing.WindWallCommand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.weather_testing.WindWallCommand.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
command: jspb.Message.getFieldWithDefault(msg, 2, 0),
condition: jspb.Message.getFieldWithDefault(msg, 3, 0),
baseWind: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
vonKarmanHeightAboveGround: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
flightId: jspb.Message.getFieldWithDefault(msg, 6, ""),
nonce: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.weather_testing.WindWallCommand}
 */
proto.weather_testing.WindWallCommand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.weather_testing.WindWallCommand;
  return proto.weather_testing.WindWallCommand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.weather_testing.WindWallCommand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.weather_testing.WindWallCommand}
 */
proto.weather_testing.WindWallCommand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {!proto.weather_testing.WindWallCommand.WindWallCommandType} */ (reader.readEnum());
      msg.setCommand(value);
      break;
    case 3:
      var value = /** @type {!proto.weather_testing.WeatherTestingConditionType} */ (reader.readEnum());
      msg.setCondition(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBaseWind(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVonKarmanHeightAboveGround(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setNonce(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.weather_testing.WindWallCommand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.weather_testing.WindWallCommand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.weather_testing.WindWallCommand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.weather_testing.WindWallCommand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getCommand();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCondition();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getBaseWind();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getVonKarmanHeightAboveGround();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getNonce();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.weather_testing.WindWallCommand.WindWallCommandType = {
  STOP: 0,
  CONSTANT: 1,
  VON_KARMAN: 2
};

/**
 * optional uint64 utime = 1;
 * @return {number}
 */
proto.weather_testing.WindWallCommand.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.weather_testing.WindWallCommand} returns this
 */
proto.weather_testing.WindWallCommand.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional WindWallCommandType command = 2;
 * @return {!proto.weather_testing.WindWallCommand.WindWallCommandType}
 */
proto.weather_testing.WindWallCommand.prototype.getCommand = function() {
  return /** @type {!proto.weather_testing.WindWallCommand.WindWallCommandType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.weather_testing.WindWallCommand.WindWallCommandType} value
 * @return {!proto.weather_testing.WindWallCommand} returns this
 */
proto.weather_testing.WindWallCommand.prototype.setCommand = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional WeatherTestingConditionType condition = 3;
 * @return {!proto.weather_testing.WeatherTestingConditionType}
 */
proto.weather_testing.WindWallCommand.prototype.getCondition = function() {
  return /** @type {!proto.weather_testing.WeatherTestingConditionType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.weather_testing.WeatherTestingConditionType} value
 * @return {!proto.weather_testing.WindWallCommand} returns this
 */
proto.weather_testing.WindWallCommand.prototype.setCondition = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional double base_wind = 4;
 * @return {number}
 */
proto.weather_testing.WindWallCommand.prototype.getBaseWind = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.weather_testing.WindWallCommand} returns this
 */
proto.weather_testing.WindWallCommand.prototype.setBaseWind = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double von_karman_height_above_ground = 5;
 * @return {number}
 */
proto.weather_testing.WindWallCommand.prototype.getVonKarmanHeightAboveGround = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.weather_testing.WindWallCommand} returns this
 */
proto.weather_testing.WindWallCommand.prototype.setVonKarmanHeightAboveGround = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string flight_id = 6;
 * @return {string}
 */
proto.weather_testing.WindWallCommand.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.weather_testing.WindWallCommand} returns this
 */
proto.weather_testing.WindWallCommand.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional uint64 nonce = 7;
 * @return {number}
 */
proto.weather_testing.WindWallCommand.prototype.getNonce = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.weather_testing.WindWallCommand} returns this
 */
proto.weather_testing.WindWallCommand.prototype.setNonce = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.weather_testing.WindWallWind.prototype.toObject = function(opt_includeInstance) {
  return proto.weather_testing.WindWallWind.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.weather_testing.WindWallWind} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.weather_testing.WindWallWind.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
wind: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
executingCommand: (f = msg.getExecutingCommand()) && proto.weather_testing.WindWallCommand.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.weather_testing.WindWallWind}
 */
proto.weather_testing.WindWallWind.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.weather_testing.WindWallWind;
  return proto.weather_testing.WindWallWind.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.weather_testing.WindWallWind} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.weather_testing.WindWallWind}
 */
proto.weather_testing.WindWallWind.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWind(value);
      break;
    case 3:
      var value = new proto.weather_testing.WindWallCommand;
      reader.readMessage(value,proto.weather_testing.WindWallCommand.deserializeBinaryFromReader);
      msg.setExecutingCommand(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.weather_testing.WindWallWind.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.weather_testing.WindWallWind.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.weather_testing.WindWallWind} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.weather_testing.WindWallWind.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getWind();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getExecutingCommand();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.weather_testing.WindWallCommand.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 utime = 1;
 * @return {number}
 */
proto.weather_testing.WindWallWind.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.weather_testing.WindWallWind} returns this
 */
proto.weather_testing.WindWallWind.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double wind = 2;
 * @return {number}
 */
proto.weather_testing.WindWallWind.prototype.getWind = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.weather_testing.WindWallWind} returns this
 */
proto.weather_testing.WindWallWind.prototype.setWind = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional WindWallCommand executing_command = 3;
 * @return {?proto.weather_testing.WindWallCommand}
 */
proto.weather_testing.WindWallWind.prototype.getExecutingCommand = function() {
  return /** @type{?proto.weather_testing.WindWallCommand} */ (
    jspb.Message.getWrapperField(this, proto.weather_testing.WindWallCommand, 3));
};


/**
 * @param {?proto.weather_testing.WindWallCommand|undefined} value
 * @return {!proto.weather_testing.WindWallWind} returns this
*/
proto.weather_testing.WindWallWind.prototype.setExecutingCommand = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.weather_testing.WindWallWind} returns this
 */
proto.weather_testing.WindWallWind.prototype.clearExecutingCommand = function() {
  return this.setExecutingCommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.weather_testing.WindWallWind.prototype.hasExecutingCommand = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.weather_testing.RainRigCommand.prototype.toObject = function(opt_includeInstance) {
  return proto.weather_testing.RainRigCommand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.weather_testing.RainRigCommand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.weather_testing.RainRigCommand.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
command: jspb.Message.getFieldWithDefault(msg, 2, 0),
condition: jspb.Message.getFieldWithDefault(msg, 3, 0),
flightId: jspb.Message.getFieldWithDefault(msg, 4, ""),
nonce: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.weather_testing.RainRigCommand}
 */
proto.weather_testing.RainRigCommand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.weather_testing.RainRigCommand;
  return proto.weather_testing.RainRigCommand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.weather_testing.RainRigCommand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.weather_testing.RainRigCommand}
 */
proto.weather_testing.RainRigCommand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {!proto.weather_testing.RainRigCommand.RainRigCommandType} */ (reader.readEnum());
      msg.setCommand(value);
      break;
    case 3:
      var value = /** @type {!proto.weather_testing.WeatherTestingConditionType} */ (reader.readEnum());
      msg.setCondition(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setNonce(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.weather_testing.RainRigCommand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.weather_testing.RainRigCommand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.weather_testing.RainRigCommand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.weather_testing.RainRigCommand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getCommand();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCondition();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNonce();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.weather_testing.RainRigCommand.RainRigCommandType = {
  NONE: 0,
  LIGHT: 1,
  HEAVY: 2
};

/**
 * optional uint64 utime = 1;
 * @return {number}
 */
proto.weather_testing.RainRigCommand.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.weather_testing.RainRigCommand} returns this
 */
proto.weather_testing.RainRigCommand.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional RainRigCommandType command = 2;
 * @return {!proto.weather_testing.RainRigCommand.RainRigCommandType}
 */
proto.weather_testing.RainRigCommand.prototype.getCommand = function() {
  return /** @type {!proto.weather_testing.RainRigCommand.RainRigCommandType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.weather_testing.RainRigCommand.RainRigCommandType} value
 * @return {!proto.weather_testing.RainRigCommand} returns this
 */
proto.weather_testing.RainRigCommand.prototype.setCommand = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional WeatherTestingConditionType condition = 3;
 * @return {!proto.weather_testing.WeatherTestingConditionType}
 */
proto.weather_testing.RainRigCommand.prototype.getCondition = function() {
  return /** @type {!proto.weather_testing.WeatherTestingConditionType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.weather_testing.WeatherTestingConditionType} value
 * @return {!proto.weather_testing.RainRigCommand} returns this
 */
proto.weather_testing.RainRigCommand.prototype.setCondition = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string flight_id = 4;
 * @return {string}
 */
proto.weather_testing.RainRigCommand.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.weather_testing.RainRigCommand} returns this
 */
proto.weather_testing.RainRigCommand.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint64 nonce = 5;
 * @return {number}
 */
proto.weather_testing.RainRigCommand.prototype.getNonce = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.weather_testing.RainRigCommand} returns this
 */
proto.weather_testing.RainRigCommand.prototype.setNonce = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.weather_testing.RainRigRain.prototype.toObject = function(opt_includeInstance) {
  return proto.weather_testing.RainRigRain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.weather_testing.RainRigRain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.weather_testing.RainRigRain.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
actualRain: jspb.Message.getFieldWithDefault(msg, 2, 0),
executingCommand: (f = msg.getExecutingCommand()) && proto.weather_testing.RainRigCommand.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.weather_testing.RainRigRain}
 */
proto.weather_testing.RainRigRain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.weather_testing.RainRigRain;
  return proto.weather_testing.RainRigRain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.weather_testing.RainRigRain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.weather_testing.RainRigRain}
 */
proto.weather_testing.RainRigRain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {!proto.weather_testing.RainRigCommand.RainRigCommandType} */ (reader.readEnum());
      msg.setActualRain(value);
      break;
    case 3:
      var value = new proto.weather_testing.RainRigCommand;
      reader.readMessage(value,proto.weather_testing.RainRigCommand.deserializeBinaryFromReader);
      msg.setExecutingCommand(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.weather_testing.RainRigRain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.weather_testing.RainRigRain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.weather_testing.RainRigRain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.weather_testing.RainRigRain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getActualRain();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getExecutingCommand();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.weather_testing.RainRigCommand.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 utime = 1;
 * @return {number}
 */
proto.weather_testing.RainRigRain.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.weather_testing.RainRigRain} returns this
 */
proto.weather_testing.RainRigRain.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional RainRigCommand.RainRigCommandType actual_rain = 2;
 * @return {!proto.weather_testing.RainRigCommand.RainRigCommandType}
 */
proto.weather_testing.RainRigRain.prototype.getActualRain = function() {
  return /** @type {!proto.weather_testing.RainRigCommand.RainRigCommandType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.weather_testing.RainRigCommand.RainRigCommandType} value
 * @return {!proto.weather_testing.RainRigRain} returns this
 */
proto.weather_testing.RainRigRain.prototype.setActualRain = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional RainRigCommand executing_command = 3;
 * @return {?proto.weather_testing.RainRigCommand}
 */
proto.weather_testing.RainRigRain.prototype.getExecutingCommand = function() {
  return /** @type{?proto.weather_testing.RainRigCommand} */ (
    jspb.Message.getWrapperField(this, proto.weather_testing.RainRigCommand, 3));
};


/**
 * @param {?proto.weather_testing.RainRigCommand|undefined} value
 * @return {!proto.weather_testing.RainRigRain} returns this
*/
proto.weather_testing.RainRigRain.prototype.setExecutingCommand = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.weather_testing.RainRigRain} returns this
 */
proto.weather_testing.RainRigRain.prototype.clearExecutingCommand = function() {
  return this.setExecutingCommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.weather_testing.RainRigRain.prototype.hasExecutingCommand = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * @enum {number}
 */
proto.weather_testing.WeatherTestingConditionType = {
  ALWAYS: 0,
  FLIGHT_AND_LANDING: 1,
  TAKEOFF: 2
};

goog.object.extend(exports, proto.weather_testing);
