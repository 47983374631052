// source: pbtypes/util/global_graph/global_graph.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_uuid_util_uuid_pb = require('../../../pbtypes/gen/uuid_util/uuid_pb.js');
goog.object.extend(proto, pbtypes_gen_uuid_util_uuid_pb);
goog.exportSymbol('proto.global_graph.DeferredSaveEvent', null, global);
goog.exportSymbol('proto.global_graph.LoadRequest', null, global);
goog.exportSymbol('proto.global_graph.LoadResponse', null, global);
goog.exportSymbol('proto.global_graph.SaveRequest', null, global);
goog.exportSymbol('proto.global_graph.SaveRequest.SaveType', null, global);
goog.exportSymbol('proto.global_graph.SaveResponse', null, global);
goog.exportSymbol('proto.global_graph.SaveResponse.DeferredSaveResult', null, global);
goog.exportSymbol('proto.global_graph.SaveResponse.DeferredSaveResult.ImmediateSaveOutcome', null, global);
goog.exportSymbol('proto.global_graph.SaveResponse.ImmediateSaveResult', null, global);
goog.exportSymbol('proto.global_graph.SaveResponse.ResultCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.global_graph.LoadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.global_graph.LoadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.global_graph.LoadRequest.displayName = 'proto.global_graph.LoadRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.global_graph.LoadResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.global_graph.LoadResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.global_graph.LoadResponse.displayName = 'proto.global_graph.LoadResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.global_graph.SaveRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.global_graph.SaveRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.global_graph.SaveRequest.displayName = 'proto.global_graph.SaveRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.global_graph.SaveResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.global_graph.SaveResponse.oneofGroups_);
};
goog.inherits(proto.global_graph.SaveResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.global_graph.SaveResponse.displayName = 'proto.global_graph.SaveResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.global_graph.SaveResponse.ImmediateSaveResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.global_graph.SaveResponse.ImmediateSaveResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.global_graph.SaveResponse.ImmediateSaveResult.displayName = 'proto.global_graph.SaveResponse.ImmediateSaveResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.global_graph.SaveResponse.DeferredSaveResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.global_graph.SaveResponse.DeferredSaveResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.global_graph.SaveResponse.DeferredSaveResult.displayName = 'proto.global_graph.SaveResponse.DeferredSaveResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.global_graph.DeferredSaveEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.global_graph.DeferredSaveEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.global_graph.DeferredSaveEvent.displayName = 'proto.global_graph.DeferredSaveEvent';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.global_graph.LoadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.global_graph.LoadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.global_graph.LoadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.global_graph.LoadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
requestUuid: (f = msg.getRequestUuid()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f),
path: jspb.Message.getFieldWithDefault(msg, 3, ""),
noUpdateWithNcpg: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.global_graph.LoadRequest}
 */
proto.global_graph.LoadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.global_graph.LoadRequest;
  return proto.global_graph.LoadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.global_graph.LoadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.global_graph.LoadRequest}
 */
proto.global_graph.LoadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setRequestUuid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoUpdateWithNcpg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.global_graph.LoadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.global_graph.LoadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.global_graph.LoadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.global_graph.LoadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRequestUuid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNoUpdateWithNcpg();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.global_graph.LoadRequest.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.global_graph.LoadRequest} returns this
 */
proto.global_graph.LoadRequest.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uuid_util.Uuid request_uuid = 2;
 * @return {?proto.uuid_util.Uuid}
 */
proto.global_graph.LoadRequest.prototype.getRequestUuid = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 2));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.global_graph.LoadRequest} returns this
*/
proto.global_graph.LoadRequest.prototype.setRequestUuid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.global_graph.LoadRequest} returns this
 */
proto.global_graph.LoadRequest.prototype.clearRequestUuid = function() {
  return this.setRequestUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.global_graph.LoadRequest.prototype.hasRequestUuid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string path = 3;
 * @return {string}
 */
proto.global_graph.LoadRequest.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.global_graph.LoadRequest} returns this
 */
proto.global_graph.LoadRequest.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool no_update_with_ncpg = 4;
 * @return {boolean}
 */
proto.global_graph.LoadRequest.prototype.getNoUpdateWithNcpg = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.global_graph.LoadRequest} returns this
 */
proto.global_graph.LoadRequest.prototype.setNoUpdateWithNcpg = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.global_graph.LoadResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.global_graph.LoadResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.global_graph.LoadResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.global_graph.LoadResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
requestUuid: (f = msg.getRequestUuid()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f),
success: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.global_graph.LoadResponse}
 */
proto.global_graph.LoadResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.global_graph.LoadResponse;
  return proto.global_graph.LoadResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.global_graph.LoadResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.global_graph.LoadResponse}
 */
proto.global_graph.LoadResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setRequestUuid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.global_graph.LoadResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.global_graph.LoadResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.global_graph.LoadResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.global_graph.LoadResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRequestUuid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.global_graph.LoadResponse.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.global_graph.LoadResponse} returns this
 */
proto.global_graph.LoadResponse.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uuid_util.Uuid request_uuid = 2;
 * @return {?proto.uuid_util.Uuid}
 */
proto.global_graph.LoadResponse.prototype.getRequestUuid = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 2));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.global_graph.LoadResponse} returns this
*/
proto.global_graph.LoadResponse.prototype.setRequestUuid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.global_graph.LoadResponse} returns this
 */
proto.global_graph.LoadResponse.prototype.clearRequestUuid = function() {
  return this.setRequestUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.global_graph.LoadResponse.prototype.hasRequestUuid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool success = 3;
 * @return {boolean}
 */
proto.global_graph.LoadResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.global_graph.LoadResponse} returns this
 */
proto.global_graph.LoadResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.global_graph.SaveRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.global_graph.SaveRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.global_graph.SaveRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.global_graph.SaveRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
requestUuid: (f = msg.getRequestUuid()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f),
saveType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.global_graph.SaveRequest}
 */
proto.global_graph.SaveRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.global_graph.SaveRequest;
  return proto.global_graph.SaveRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.global_graph.SaveRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.global_graph.SaveRequest}
 */
proto.global_graph.SaveRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setRequestUuid(value);
      break;
    case 3:
      var value = /** @type {!proto.global_graph.SaveRequest.SaveType} */ (reader.readEnum());
      msg.setSaveType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.global_graph.SaveRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.global_graph.SaveRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.global_graph.SaveRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.global_graph.SaveRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRequestUuid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getSaveType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.global_graph.SaveRequest.SaveType = {
  IMMEDIATE: 0,
  WHEN_LANDING: 1
};

/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.global_graph.SaveRequest.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.global_graph.SaveRequest} returns this
 */
proto.global_graph.SaveRequest.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uuid_util.Uuid request_uuid = 2;
 * @return {?proto.uuid_util.Uuid}
 */
proto.global_graph.SaveRequest.prototype.getRequestUuid = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 2));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.global_graph.SaveRequest} returns this
*/
proto.global_graph.SaveRequest.prototype.setRequestUuid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.global_graph.SaveRequest} returns this
 */
proto.global_graph.SaveRequest.prototype.clearRequestUuid = function() {
  return this.setRequestUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.global_graph.SaveRequest.prototype.hasRequestUuid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SaveType save_type = 3;
 * @return {!proto.global_graph.SaveRequest.SaveType}
 */
proto.global_graph.SaveRequest.prototype.getSaveType = function() {
  return /** @type {!proto.global_graph.SaveRequest.SaveType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.global_graph.SaveRequest.SaveType} value
 * @return {!proto.global_graph.SaveRequest} returns this
 */
proto.global_graph.SaveRequest.prototype.setSaveType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.global_graph.SaveResponse.oneofGroups_ = [[3,4]];

/**
 * @enum {number}
 */
proto.global_graph.SaveResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  IMMEDIATE_SAVE_RESULT: 3,
  DEFERRED_SAVE_RESULT: 4
};

/**
 * @return {proto.global_graph.SaveResponse.ResultCase}
 */
proto.global_graph.SaveResponse.prototype.getResultCase = function() {
  return /** @type {proto.global_graph.SaveResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.global_graph.SaveResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.global_graph.SaveResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.global_graph.SaveResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.global_graph.SaveResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.global_graph.SaveResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
requestUuid: (f = msg.getRequestUuid()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f),
immediateSaveResult: (f = msg.getImmediateSaveResult()) && proto.global_graph.SaveResponse.ImmediateSaveResult.toObject(includeInstance, f),
deferredSaveResult: (f = msg.getDeferredSaveResult()) && proto.global_graph.SaveResponse.DeferredSaveResult.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.global_graph.SaveResponse}
 */
proto.global_graph.SaveResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.global_graph.SaveResponse;
  return proto.global_graph.SaveResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.global_graph.SaveResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.global_graph.SaveResponse}
 */
proto.global_graph.SaveResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setRequestUuid(value);
      break;
    case 3:
      var value = new proto.global_graph.SaveResponse.ImmediateSaveResult;
      reader.readMessage(value,proto.global_graph.SaveResponse.ImmediateSaveResult.deserializeBinaryFromReader);
      msg.setImmediateSaveResult(value);
      break;
    case 4:
      var value = new proto.global_graph.SaveResponse.DeferredSaveResult;
      reader.readMessage(value,proto.global_graph.SaveResponse.DeferredSaveResult.deserializeBinaryFromReader);
      msg.setDeferredSaveResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.global_graph.SaveResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.global_graph.SaveResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.global_graph.SaveResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.global_graph.SaveResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRequestUuid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getImmediateSaveResult();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.global_graph.SaveResponse.ImmediateSaveResult.serializeBinaryToWriter
    );
  }
  f = message.getDeferredSaveResult();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.global_graph.SaveResponse.DeferredSaveResult.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.global_graph.SaveResponse.ImmediateSaveResult.prototype.toObject = function(opt_includeInstance) {
  return proto.global_graph.SaveResponse.ImmediateSaveResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.global_graph.SaveResponse.ImmediateSaveResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.global_graph.SaveResponse.ImmediateSaveResult.toObject = function(includeInstance, msg) {
  var f, obj = {
success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.global_graph.SaveResponse.ImmediateSaveResult}
 */
proto.global_graph.SaveResponse.ImmediateSaveResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.global_graph.SaveResponse.ImmediateSaveResult;
  return proto.global_graph.SaveResponse.ImmediateSaveResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.global_graph.SaveResponse.ImmediateSaveResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.global_graph.SaveResponse.ImmediateSaveResult}
 */
proto.global_graph.SaveResponse.ImmediateSaveResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.global_graph.SaveResponse.ImmediateSaveResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.global_graph.SaveResponse.ImmediateSaveResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.global_graph.SaveResponse.ImmediateSaveResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.global_graph.SaveResponse.ImmediateSaveResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.global_graph.SaveResponse.ImmediateSaveResult.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.global_graph.SaveResponse.ImmediateSaveResult} returns this
 */
proto.global_graph.SaveResponse.ImmediateSaveResult.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.global_graph.SaveResponse.DeferredSaveResult.prototype.toObject = function(opt_includeInstance) {
  return proto.global_graph.SaveResponse.DeferredSaveResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.global_graph.SaveResponse.DeferredSaveResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.global_graph.SaveResponse.DeferredSaveResult.toObject = function(includeInstance, msg) {
  var f, obj = {
immediateSaveOutcome: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.global_graph.SaveResponse.DeferredSaveResult}
 */
proto.global_graph.SaveResponse.DeferredSaveResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.global_graph.SaveResponse.DeferredSaveResult;
  return proto.global_graph.SaveResponse.DeferredSaveResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.global_graph.SaveResponse.DeferredSaveResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.global_graph.SaveResponse.DeferredSaveResult}
 */
proto.global_graph.SaveResponse.DeferredSaveResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.global_graph.SaveResponse.DeferredSaveResult.ImmediateSaveOutcome} */ (reader.readEnum());
      msg.setImmediateSaveOutcome(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.global_graph.SaveResponse.DeferredSaveResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.global_graph.SaveResponse.DeferredSaveResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.global_graph.SaveResponse.DeferredSaveResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.global_graph.SaveResponse.DeferredSaveResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImmediateSaveOutcome();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.global_graph.SaveResponse.DeferredSaveResult.ImmediateSaveOutcome = {
  IMMEDIATE_SAVE_NOT_ATTEMPTED: 0,
  IMMEDIATE_SAVE_SUCCEEDED: 1,
  IMMEDIATE_SAVE_FAILED: 2
};

/**
 * optional ImmediateSaveOutcome immediate_save_outcome = 1;
 * @return {!proto.global_graph.SaveResponse.DeferredSaveResult.ImmediateSaveOutcome}
 */
proto.global_graph.SaveResponse.DeferredSaveResult.prototype.getImmediateSaveOutcome = function() {
  return /** @type {!proto.global_graph.SaveResponse.DeferredSaveResult.ImmediateSaveOutcome} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.global_graph.SaveResponse.DeferredSaveResult.ImmediateSaveOutcome} value
 * @return {!proto.global_graph.SaveResponse.DeferredSaveResult} returns this
 */
proto.global_graph.SaveResponse.DeferredSaveResult.prototype.setImmediateSaveOutcome = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.global_graph.SaveResponse.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.global_graph.SaveResponse} returns this
 */
proto.global_graph.SaveResponse.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uuid_util.Uuid request_uuid = 2;
 * @return {?proto.uuid_util.Uuid}
 */
proto.global_graph.SaveResponse.prototype.getRequestUuid = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 2));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.global_graph.SaveResponse} returns this
*/
proto.global_graph.SaveResponse.prototype.setRequestUuid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.global_graph.SaveResponse} returns this
 */
proto.global_graph.SaveResponse.prototype.clearRequestUuid = function() {
  return this.setRequestUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.global_graph.SaveResponse.prototype.hasRequestUuid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ImmediateSaveResult immediate_save_result = 3;
 * @return {?proto.global_graph.SaveResponse.ImmediateSaveResult}
 */
proto.global_graph.SaveResponse.prototype.getImmediateSaveResult = function() {
  return /** @type{?proto.global_graph.SaveResponse.ImmediateSaveResult} */ (
    jspb.Message.getWrapperField(this, proto.global_graph.SaveResponse.ImmediateSaveResult, 3));
};


/**
 * @param {?proto.global_graph.SaveResponse.ImmediateSaveResult|undefined} value
 * @return {!proto.global_graph.SaveResponse} returns this
*/
proto.global_graph.SaveResponse.prototype.setImmediateSaveResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.global_graph.SaveResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.global_graph.SaveResponse} returns this
 */
proto.global_graph.SaveResponse.prototype.clearImmediateSaveResult = function() {
  return this.setImmediateSaveResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.global_graph.SaveResponse.prototype.hasImmediateSaveResult = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DeferredSaveResult deferred_save_result = 4;
 * @return {?proto.global_graph.SaveResponse.DeferredSaveResult}
 */
proto.global_graph.SaveResponse.prototype.getDeferredSaveResult = function() {
  return /** @type{?proto.global_graph.SaveResponse.DeferredSaveResult} */ (
    jspb.Message.getWrapperField(this, proto.global_graph.SaveResponse.DeferredSaveResult, 4));
};


/**
 * @param {?proto.global_graph.SaveResponse.DeferredSaveResult|undefined} value
 * @return {!proto.global_graph.SaveResponse} returns this
*/
proto.global_graph.SaveResponse.prototype.setDeferredSaveResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.global_graph.SaveResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.global_graph.SaveResponse} returns this
 */
proto.global_graph.SaveResponse.prototype.clearDeferredSaveResult = function() {
  return this.setDeferredSaveResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.global_graph.SaveResponse.prototype.hasDeferredSaveResult = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.global_graph.DeferredSaveEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.global_graph.DeferredSaveEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.global_graph.DeferredSaveEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.global_graph.DeferredSaveEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
requestUuid: (f = msg.getRequestUuid()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f),
success: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.global_graph.DeferredSaveEvent}
 */
proto.global_graph.DeferredSaveEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.global_graph.DeferredSaveEvent;
  return proto.global_graph.DeferredSaveEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.global_graph.DeferredSaveEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.global_graph.DeferredSaveEvent}
 */
proto.global_graph.DeferredSaveEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setRequestUuid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.global_graph.DeferredSaveEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.global_graph.DeferredSaveEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.global_graph.DeferredSaveEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.global_graph.DeferredSaveEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRequestUuid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.global_graph.DeferredSaveEvent.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.global_graph.DeferredSaveEvent} returns this
 */
proto.global_graph.DeferredSaveEvent.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uuid_util.Uuid request_uuid = 2;
 * @return {?proto.uuid_util.Uuid}
 */
proto.global_graph.DeferredSaveEvent.prototype.getRequestUuid = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 2));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.global_graph.DeferredSaveEvent} returns this
*/
proto.global_graph.DeferredSaveEvent.prototype.setRequestUuid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.global_graph.DeferredSaveEvent} returns this
 */
proto.global_graph.DeferredSaveEvent.prototype.clearRequestUuid = function() {
  return this.setRequestUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.global_graph.DeferredSaveEvent.prototype.hasRequestUuid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool success = 3;
 * @return {boolean}
 */
proto.global_graph.DeferredSaveEvent.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.global_graph.DeferredSaveEvent} returns this
 */
proto.global_graph.DeferredSaveEvent.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


goog.object.extend(exports, proto.global_graph);
