// source: pbtypes/vehicle/skills/actions/take_photo/take_photo.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_vehicle_skills_mission_camera_settings_pb = require('../../../../../pbtypes/vehicle/skills/mission_camera_settings_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_mission_camera_settings_pb);
var pbtypes_gen_user_camera_photo_format_pb = require('../../../../../pbtypes/gen/user_camera/photo_format_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_photo_format_pb);
goog.exportSymbol('proto.skills.ExtraPhotoMetadata', null, global);
goog.exportSymbol('proto.skills.Photo', null, global);
goog.exportSymbol('proto.skills.TakePhotoActionArgs', null, global);
goog.exportSymbol('proto.skills.TakePhotoActionStateData', null, global);
goog.exportSymbol('proto.skills.TakePhotoResultData', null, global);
goog.exportSymbol('proto.skills.TakePhotoResultData.PhotoResult', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.ExtraPhotoMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.ExtraPhotoMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.ExtraPhotoMetadata.displayName = 'proto.skills.ExtraPhotoMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.TakePhotoActionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.TakePhotoActionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.TakePhotoActionArgs.displayName = 'proto.skills.TakePhotoActionArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.Photo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.Photo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.Photo.displayName = 'proto.skills.Photo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.TakePhotoResultData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.TakePhotoResultData.repeatedFields_, null);
};
goog.inherits(proto.skills.TakePhotoResultData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.TakePhotoResultData.displayName = 'proto.skills.TakePhotoResultData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.TakePhotoActionStateData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.TakePhotoActionStateData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.TakePhotoActionStateData.displayName = 'proto.skills.TakePhotoActionStateData';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.ExtraPhotoMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.ExtraPhotoMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.ExtraPhotoMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.ExtraPhotoMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
capturePointName: jspb.Message.getFieldWithDefault(msg, 1, ""),
structureName: jspb.Message.getFieldWithDefault(msg, 2, ""),
structureId: jspb.Message.getFieldWithDefault(msg, 3, ""),
capturePointId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.ExtraPhotoMetadata}
 */
proto.skills.ExtraPhotoMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.ExtraPhotoMetadata;
  return proto.skills.ExtraPhotoMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.ExtraPhotoMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.ExtraPhotoMetadata}
 */
proto.skills.ExtraPhotoMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCapturePointName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructureName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructureId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCapturePointId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.ExtraPhotoMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.ExtraPhotoMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.ExtraPhotoMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.ExtraPhotoMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCapturePointName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructureName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStructureId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCapturePointId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string capture_point_name = 1;
 * @return {string}
 */
proto.skills.ExtraPhotoMetadata.prototype.getCapturePointName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.ExtraPhotoMetadata} returns this
 */
proto.skills.ExtraPhotoMetadata.prototype.setCapturePointName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure_name = 2;
 * @return {string}
 */
proto.skills.ExtraPhotoMetadata.prototype.getStructureName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.ExtraPhotoMetadata} returns this
 */
proto.skills.ExtraPhotoMetadata.prototype.setStructureName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string structure_id = 3;
 * @return {string}
 */
proto.skills.ExtraPhotoMetadata.prototype.getStructureId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.ExtraPhotoMetadata} returns this
 */
proto.skills.ExtraPhotoMetadata.prototype.setStructureId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string capture_point_id = 4;
 * @return {string}
 */
proto.skills.ExtraPhotoMetadata.prototype.getCapturePointId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.ExtraPhotoMetadata} returns this
 */
proto.skills.ExtraPhotoMetadata.prototype.setCapturePointId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.TakePhotoActionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.TakePhotoActionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.TakePhotoActionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TakePhotoActionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
photoType: (f = msg.getPhotoType()) && pbtypes_vehicle_skills_mission_camera_settings_pb.PhotoType.toObject(includeInstance, f),
extraMetadata: (f = msg.getExtraMetadata()) && proto.skills.ExtraPhotoMetadata.toObject(includeInstance, f),
cameraSettings: (f = msg.getCameraSettings()) && pbtypes_vehicle_skills_mission_camera_settings_pb.CameraSettings.toObject(includeInstance, f),
photoSettings: (f = msg.getPhotoSettings()) && pbtypes_vehicle_skills_mission_camera_settings_pb.PhotoSettings.toObject(includeInstance, f),
waitForFocusToConverge: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.TakePhotoActionArgs}
 */
proto.skills.TakePhotoActionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.TakePhotoActionArgs;
  return proto.skills.TakePhotoActionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.TakePhotoActionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.TakePhotoActionArgs}
 */
proto.skills.TakePhotoActionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_vehicle_skills_mission_camera_settings_pb.PhotoType;
      reader.readMessage(value,pbtypes_vehicle_skills_mission_camera_settings_pb.PhotoType.deserializeBinaryFromReader);
      msg.setPhotoType(value);
      break;
    case 2:
      var value = new proto.skills.ExtraPhotoMetadata;
      reader.readMessage(value,proto.skills.ExtraPhotoMetadata.deserializeBinaryFromReader);
      msg.setExtraMetadata(value);
      break;
    case 3:
      var value = new pbtypes_vehicle_skills_mission_camera_settings_pb.CameraSettings;
      reader.readMessage(value,pbtypes_vehicle_skills_mission_camera_settings_pb.CameraSettings.deserializeBinaryFromReader);
      msg.setCameraSettings(value);
      break;
    case 4:
      var value = new pbtypes_vehicle_skills_mission_camera_settings_pb.PhotoSettings;
      reader.readMessage(value,pbtypes_vehicle_skills_mission_camera_settings_pb.PhotoSettings.deserializeBinaryFromReader);
      msg.setPhotoSettings(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWaitForFocusToConverge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.TakePhotoActionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.TakePhotoActionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.TakePhotoActionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TakePhotoActionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhotoType();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_vehicle_skills_mission_camera_settings_pb.PhotoType.serializeBinaryToWriter
    );
  }
  f = message.getExtraMetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.skills.ExtraPhotoMetadata.serializeBinaryToWriter
    );
  }
  f = message.getCameraSettings();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_vehicle_skills_mission_camera_settings_pb.CameraSettings.serializeBinaryToWriter
    );
  }
  f = message.getPhotoSettings();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      pbtypes_vehicle_skills_mission_camera_settings_pb.PhotoSettings.serializeBinaryToWriter
    );
  }
  f = message.getWaitForFocusToConverge();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional PhotoType photo_type = 1;
 * @return {?proto.skills.PhotoType}
 */
proto.skills.TakePhotoActionArgs.prototype.getPhotoType = function() {
  return /** @type{?proto.skills.PhotoType} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_mission_camera_settings_pb.PhotoType, 1));
};


/**
 * @param {?proto.skills.PhotoType|undefined} value
 * @return {!proto.skills.TakePhotoActionArgs} returns this
*/
proto.skills.TakePhotoActionArgs.prototype.setPhotoType = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.TakePhotoActionArgs} returns this
 */
proto.skills.TakePhotoActionArgs.prototype.clearPhotoType = function() {
  return this.setPhotoType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.TakePhotoActionArgs.prototype.hasPhotoType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ExtraPhotoMetadata extra_metadata = 2;
 * @return {?proto.skills.ExtraPhotoMetadata}
 */
proto.skills.TakePhotoActionArgs.prototype.getExtraMetadata = function() {
  return /** @type{?proto.skills.ExtraPhotoMetadata} */ (
    jspb.Message.getWrapperField(this, proto.skills.ExtraPhotoMetadata, 2));
};


/**
 * @param {?proto.skills.ExtraPhotoMetadata|undefined} value
 * @return {!proto.skills.TakePhotoActionArgs} returns this
*/
proto.skills.TakePhotoActionArgs.prototype.setExtraMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.TakePhotoActionArgs} returns this
 */
proto.skills.TakePhotoActionArgs.prototype.clearExtraMetadata = function() {
  return this.setExtraMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.TakePhotoActionArgs.prototype.hasExtraMetadata = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CameraSettings camera_settings = 3;
 * @return {?proto.skills.CameraSettings}
 */
proto.skills.TakePhotoActionArgs.prototype.getCameraSettings = function() {
  return /** @type{?proto.skills.CameraSettings} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_mission_camera_settings_pb.CameraSettings, 3));
};


/**
 * @param {?proto.skills.CameraSettings|undefined} value
 * @return {!proto.skills.TakePhotoActionArgs} returns this
*/
proto.skills.TakePhotoActionArgs.prototype.setCameraSettings = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.TakePhotoActionArgs} returns this
 */
proto.skills.TakePhotoActionArgs.prototype.clearCameraSettings = function() {
  return this.setCameraSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.TakePhotoActionArgs.prototype.hasCameraSettings = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PhotoSettings photo_settings = 4;
 * @return {?proto.skills.PhotoSettings}
 */
proto.skills.TakePhotoActionArgs.prototype.getPhotoSettings = function() {
  return /** @type{?proto.skills.PhotoSettings} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_mission_camera_settings_pb.PhotoSettings, 4));
};


/**
 * @param {?proto.skills.PhotoSettings|undefined} value
 * @return {!proto.skills.TakePhotoActionArgs} returns this
*/
proto.skills.TakePhotoActionArgs.prototype.setPhotoSettings = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.TakePhotoActionArgs} returns this
 */
proto.skills.TakePhotoActionArgs.prototype.clearPhotoSettings = function() {
  return this.setPhotoSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.TakePhotoActionArgs.prototype.hasPhotoSettings = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool wait_for_focus_to_converge = 5;
 * @return {boolean}
 */
proto.skills.TakePhotoActionArgs.prototype.getWaitForFocusToConverge = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.TakePhotoActionArgs} returns this
 */
proto.skills.TakePhotoActionArgs.prototype.setWaitForFocusToConverge = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.Photo.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.Photo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.Photo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.Photo.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
requestUtime: jspb.Message.getFieldWithDefault(msg, 2, 0),
path: jspb.Message.getFieldWithDefault(msg, 3, ""),
mediaId: jspb.Message.getFieldWithDefault(msg, 4, ""),
photoFormat: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.Photo}
 */
proto.skills.Photo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.Photo;
  return proto.skills.Photo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.Photo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.Photo}
 */
proto.skills.Photo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRequestUtime(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMediaId(value);
      break;
    case 5:
      var value = /** @type {!proto.user_camera.PhotoFormat.Enum} */ (reader.readEnum());
      msg.setPhotoFormat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.Photo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.Photo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.Photo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.Photo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRequestUtime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMediaId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhotoFormat();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.skills.Photo.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.Photo} returns this
 */
proto.skills.Photo.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 request_utime = 2;
 * @return {number}
 */
proto.skills.Photo.prototype.getRequestUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.Photo} returns this
 */
proto.skills.Photo.prototype.setRequestUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string path = 3;
 * @return {string}
 */
proto.skills.Photo.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.Photo} returns this
 */
proto.skills.Photo.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string media_id = 4;
 * @return {string}
 */
proto.skills.Photo.prototype.getMediaId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.Photo} returns this
 */
proto.skills.Photo.prototype.setMediaId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional user_camera.PhotoFormat.Enum photo_format = 5;
 * @return {!proto.user_camera.PhotoFormat.Enum}
 */
proto.skills.Photo.prototype.getPhotoFormat = function() {
  return /** @type {!proto.user_camera.PhotoFormat.Enum} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.user_camera.PhotoFormat.Enum} value
 * @return {!proto.skills.Photo} returns this
 */
proto.skills.Photo.prototype.setPhotoFormat = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.TakePhotoResultData.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.TakePhotoResultData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.TakePhotoResultData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.TakePhotoResultData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TakePhotoResultData.toObject = function(includeInstance, msg) {
  var f, obj = {
eoResult: jspb.Message.getFieldWithDefault(msg, 1, 0),
irResult: jspb.Message.getFieldWithDefault(msg, 2, 0),
eoPhotosList: jspb.Message.toObjectList(msg.getEoPhotosList(),
    proto.skills.Photo.toObject, includeInstance),
irPhotosList: jspb.Message.toObjectList(msg.getIrPhotosList(),
    proto.skills.Photo.toObject, includeInstance),
eoFocusConvergenceTimedOut: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.TakePhotoResultData}
 */
proto.skills.TakePhotoResultData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.TakePhotoResultData;
  return proto.skills.TakePhotoResultData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.TakePhotoResultData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.TakePhotoResultData}
 */
proto.skills.TakePhotoResultData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.skills.TakePhotoResultData.PhotoResult} */ (reader.readEnum());
      msg.setEoResult(value);
      break;
    case 2:
      var value = /** @type {!proto.skills.TakePhotoResultData.PhotoResult} */ (reader.readEnum());
      msg.setIrResult(value);
      break;
    case 3:
      var value = new proto.skills.Photo;
      reader.readMessage(value,proto.skills.Photo.deserializeBinaryFromReader);
      msg.addEoPhotos(value);
      break;
    case 4:
      var value = new proto.skills.Photo;
      reader.readMessage(value,proto.skills.Photo.deserializeBinaryFromReader);
      msg.addIrPhotos(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEoFocusConvergenceTimedOut(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.TakePhotoResultData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.TakePhotoResultData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.TakePhotoResultData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TakePhotoResultData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEoResult();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getIrResult();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getEoPhotosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.skills.Photo.serializeBinaryToWriter
    );
  }
  f = message.getIrPhotosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.skills.Photo.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.skills.TakePhotoResultData.PhotoResult = {
  UNKNOWN: 0,
  SUCCESS: 1,
  SKIPPED: 2,
  FAILURE: 3
};

/**
 * optional PhotoResult eo_result = 1;
 * @return {!proto.skills.TakePhotoResultData.PhotoResult}
 */
proto.skills.TakePhotoResultData.prototype.getEoResult = function() {
  return /** @type {!proto.skills.TakePhotoResultData.PhotoResult} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.skills.TakePhotoResultData.PhotoResult} value
 * @return {!proto.skills.TakePhotoResultData} returns this
 */
proto.skills.TakePhotoResultData.prototype.setEoResult = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional PhotoResult ir_result = 2;
 * @return {!proto.skills.TakePhotoResultData.PhotoResult}
 */
proto.skills.TakePhotoResultData.prototype.getIrResult = function() {
  return /** @type {!proto.skills.TakePhotoResultData.PhotoResult} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.skills.TakePhotoResultData.PhotoResult} value
 * @return {!proto.skills.TakePhotoResultData} returns this
 */
proto.skills.TakePhotoResultData.prototype.setIrResult = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated Photo eo_photos = 3;
 * @return {!Array<!proto.skills.Photo>}
 */
proto.skills.TakePhotoResultData.prototype.getEoPhotosList = function() {
  return /** @type{!Array<!proto.skills.Photo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skills.Photo, 3));
};


/**
 * @param {!Array<!proto.skills.Photo>} value
 * @return {!proto.skills.TakePhotoResultData} returns this
*/
proto.skills.TakePhotoResultData.prototype.setEoPhotosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.skills.Photo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.Photo}
 */
proto.skills.TakePhotoResultData.prototype.addEoPhotos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.skills.Photo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.TakePhotoResultData} returns this
 */
proto.skills.TakePhotoResultData.prototype.clearEoPhotosList = function() {
  return this.setEoPhotosList([]);
};


/**
 * repeated Photo ir_photos = 4;
 * @return {!Array<!proto.skills.Photo>}
 */
proto.skills.TakePhotoResultData.prototype.getIrPhotosList = function() {
  return /** @type{!Array<!proto.skills.Photo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skills.Photo, 4));
};


/**
 * @param {!Array<!proto.skills.Photo>} value
 * @return {!proto.skills.TakePhotoResultData} returns this
*/
proto.skills.TakePhotoResultData.prototype.setIrPhotosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.skills.Photo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.Photo}
 */
proto.skills.TakePhotoResultData.prototype.addIrPhotos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.skills.Photo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.TakePhotoResultData} returns this
 */
proto.skills.TakePhotoResultData.prototype.clearIrPhotosList = function() {
  return this.setIrPhotosList([]);
};


/**
 * optional bool eo_focus_convergence_timed_out = 5;
 * @return {boolean}
 */
proto.skills.TakePhotoResultData.prototype.getEoFocusConvergenceTimedOut = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.TakePhotoResultData} returns this
 */
proto.skills.TakePhotoResultData.prototype.setEoFocusConvergenceTimedOut = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.skills.TakePhotoResultData} returns this
 */
proto.skills.TakePhotoResultData.prototype.clearEoFocusConvergenceTimedOut = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.TakePhotoResultData.prototype.hasEoFocusConvergenceTimedOut = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.TakePhotoActionStateData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.TakePhotoActionStateData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.TakePhotoActionStateData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TakePhotoActionStateData.toObject = function(includeInstance, msg) {
  var f, obj = {
eoRequestUtime: jspb.Message.getFieldWithDefault(msg, 1, 0),
irRequestUtime: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.TakePhotoActionStateData}
 */
proto.skills.TakePhotoActionStateData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.TakePhotoActionStateData;
  return proto.skills.TakePhotoActionStateData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.TakePhotoActionStateData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.TakePhotoActionStateData}
 */
proto.skills.TakePhotoActionStateData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEoRequestUtime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIrRequestUtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.TakePhotoActionStateData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.TakePhotoActionStateData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.TakePhotoActionStateData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TakePhotoActionStateData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEoRequestUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getIrRequestUtime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 eo_request_utime = 1;
 * @return {number}
 */
proto.skills.TakePhotoActionStateData.prototype.getEoRequestUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.TakePhotoActionStateData} returns this
 */
proto.skills.TakePhotoActionStateData.prototype.setEoRequestUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 ir_request_utime = 2;
 * @return {number}
 */
proto.skills.TakePhotoActionStateData.prototype.getIrRequestUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.TakePhotoActionStateData} returns this
 */
proto.skills.TakePhotoActionStateData.prototype.setIrRequestUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


goog.object.extend(exports, proto.skills);
