import Cookie from "js-cookie";
import _ from "lodash";

import { CloudErrorCode } from "@skydio/pbtypes/pbtypes/gen/cloud_api/cloud_error_code_pb";
import { sleep } from "@skydio/core";
import { makeSendRequest, FetchError } from "../../common/http";
import endpoints from "./endpoints";
import {
  checkAPIStatus,
  checkStatus,
  isDeployedCloudApi,
  LOCAL_CLOUD_API_URL,
  parseResponse,
} from "./requests-common";

declare global {
  interface Window {
    // Needs to line up with the types from next-runtime-env
    __ENV: NodeJS.ProcessEnv;
    REACT_APP_BACKEND_URL: string;
  }
}

// NOTE(sam): flask-jwt-extended sends `access_token_cookie` and `refresh_token_cookie`, both of
// which are JWTs and are HttpOnly cookies so they aren't visible to client-side JavaScript. The
// fact that these cookies are automatically exchanged with the cloud api is not visibly captured
// in the logic in this file but is key to the authentication flow.
const CSRF_ACCESS_TOKEN_COOKIE = "csrf_access_token";
const CSRF_REFRESH_TOKEN_COOKIE = "csrf_refresh_token";
/**
 * Added for Streamlit. We want to create a python client for an authed cloud api user in a
 * Streamlit session without requiring the user to authenticate via login code each session.
 * So we store a refresh token in a cookie to persist it across sessions. Cloud api doesn't
 * use the cookie. It is managed in js only.
 */
const PY_CLIENT_REFRESH_TOKEN_COOKIE = "py_client_refresh_token";

// NOTE(sam): Theoretically we shouldn't have any logic in the api_util library that cares about
// environment variables so that it can be agnostic to the app it's being used in, but we need this
// for now while we still do data fetching from shared Redux actions defined in api_util (without
// doing a significant refactor to the Redux stuff to pipe through the cloud api url differently).
// For now we'll just keep it contained to this module.
const CLOUD_API_URL = (function () {
  if (typeof window !== "undefined") {
    // Check if the url is provided by next-runtime-env (this should the the case in customer portal)
    if (window.__ENV) {
      return window.__ENV.NEXT_PUBLIC_BACKEND_URL ?? LOCAL_CLOUD_API_URL;
    }
    return (
      // Check if it's defined globally by the config.js file (should be the case in prod admin portal)
      window.REACT_APP_BACKEND_URL ??
      // Check if available in CRA environment (admin portal dev)
      process.env.REACT_APP_BACKEND_URL ??
      // Logdash sets this one
      process.env.NEXT_PUBLIC_BACKEND_URL ??
      // Default to local cloud api if nothing specified
      LOCAL_CLOUD_API_URL
    );
  }
  // Server environment only applicable for NextJS
  return process.env.NEXT_PUBLIC_BACKEND_URL ?? LOCAL_CLOUD_API_URL;
})();

export const CLOUD_API_DEPLOYED = isDeployedCloudApi(CLOUD_API_URL);

/*
  This is the allowlist of domains to _rewrite_ the cookie domain for CSRF tokens.
  If the domain matches up to one of these, we update the cookie domain to match.

  Without this logic CSRF tokens aren't accessible to development apps.
*/
export const getCookieDomain = () => {
  const hostname = window?.location?.hostname ?? "";
  // Share cookies only across skydio.com and skydio-dev.com subdomains.
  let domain = "";
  if (
    hostname.endsWith(".skydio.com") ||
    hostname.endsWith(".skydio-dev.com") ||
    hostname.endsWith(".skyd.io")
  ) {
    // snip the first element off and support everything one step up.
    // This allows us to handle onprem deployments and coder deployments
    // without CSRF token cookies overriding or corrupting eachother.
    // as much.
    // I hope.
    domain = hostname.split(".").slice(1).join(".");
  } else if (hostname.endsWith("localhost")) {
    domain = "localhost";
  } else if (hostname.endsWith("skydio-vercel.com")) {
    domain = "skydio-vercel.com";
  }

  return domain;
};

const CSRF_COOKIE_DOMAIN = (function () {
  if (typeof window === "undefined") {
    return "";
  }

  return getCookieDomain();
})();

export const getPythonClientRefreshToken = () => {
  return Cookie.get(PY_CLIENT_REFRESH_TOKEN_COOKIE) || "";
};

export const setPythonClientRefreshToken = (token: string) => {
  return Cookie.set(PY_CLIENT_REFRESH_TOKEN_COOKIE, token, {
    domain: CLOUD_API_DEPLOYED ? CSRF_COOKIE_DOMAIN : undefined,
    secure: CLOUD_API_DEPLOYED,
    expires: 365,
  });
};

/*
  CSRF Handling Logic.

  When we get the CSRF tokens from cloud-api as cookies, they're durable, but they're not
  synchronously delivered. Both the auth token and the CSRF token cookie are parsed into the
  browser cookie store at some time _after_ authentication resolves.

  This results in the potential for mostly-automated solutions to send requests while we're still
  in an unsettled state, causing us to send an empty CSRF token along with our auth token, and
  then we're sad because that crashes the app.

  So, when we process a response that includes the CSRF tokens _as headers_, we're pulling them into
  this read-through cache. This would include authenticate, saml/oidc/axon, and refresh responses.

  Backend returns both headers and cookies for this reason - cookies are durable, but the headers
  don't require us to parse the cookie header manually to get our mitts on them.

  In _normal_ play, when we've authenticated sometime in the past, we may have the CSRF token in
  cookie form but not in app state. We'll pull the cookie value into the read-through.

  To make sure we have access to the cookies, we write the CSRF headers we received
  to the frontend domain, assuming that's in an acceptable allowlist.

  Without this logic, development instances of frontend apps lose session
  every refresh since they lose the cookie and can't recover.

  The goal here is to ensure that by the time we resolve the sendRequest that returned an auth
  cookie and associated CSRF token, the CSRF token is available to frontend state 100% of the time.
*/
let csrf_access_token: string | undefined;
let csrf_refresh_token: string | undefined;

export function getCSRFAccessToken() {
  if (csrf_access_token) {
    return csrf_access_token;
  }
  csrf_access_token = Cookie.get(CSRF_ACCESS_TOKEN_COOKIE);
  return csrf_access_token ?? "";
}

function getCSRFRefreshToken() {
  if (csrf_refresh_token) {
    return csrf_refresh_token;
  }
  csrf_refresh_token = Cookie.get(CSRF_REFRESH_TOKEN_COOKIE);
  return csrf_refresh_token ?? "";
}

function updateCSRFTokens(response: Response) {
  if (response.headers.has("X-Csrf_access_token")) {
    // Store CSRF tokens in memory to mitigate race conditions.
    csrf_access_token = response.headers.get("X-Csrf_access_token")!;

    // Rewrite the cookie with the updated token and the (allowlisted) frontend domain.
    Cookie.remove(CSRF_ACCESS_TOKEN_COOKIE);
    setCSRFAccessToken(response.headers.get("X-csrf_access_token")!);
  }
  if (response.headers.has("X-Csrf_refresh_token")) {
    // Store CSRF tokens in memory to mitigate race conditions.
    csrf_refresh_token = response.headers.get("X-Csrf_refresh_token")!;

    // Rewrite the cookie with the updated token and the (allowlisted) frontend domain.
    Cookie.remove(CSRF_REFRESH_TOKEN_COOKIE);
    setCSRFRefreshToken(response.headers.get("X-csrf_refresh_token")!);
  }
  return response;
}

function setCSRFAccessToken(token: string) {
  return Cookie.set(CSRF_ACCESS_TOKEN_COOKIE, token, {
    // skydio.com allows access to all subdomains
    domain: CLOUD_API_DEPLOYED ? CSRF_COOKIE_DOMAIN : undefined,
    secure: CLOUD_API_DEPLOYED,
    expires: 365,
  });
}

function setCSRFRefreshToken(token: string) {
  return Cookie.set(CSRF_REFRESH_TOKEN_COOKIE, token, {
    // skydio.com allows access to all subdomains
    domain: CLOUD_API_DEPLOYED ? CSRF_COOKIE_DOMAIN : undefined,
    secure: CLOUD_API_DEPLOYED,
    expires: 365,
  });
}

export function logoutRemoveCookies() {
  Cookie.remove(CSRF_ACCESS_TOKEN_COOKIE, {
    domain: CLOUD_API_DEPLOYED ? CSRF_COOKIE_DOMAIN : undefined,
  });
  Cookie.remove(CSRF_REFRESH_TOKEN_COOKIE, {
    domain: CLOUD_API_DEPLOYED ? CSRF_COOKIE_DOMAIN : undefined,
  });
  Cookie.remove(PY_CLIENT_REFRESH_TOKEN_COOKIE, {
    domain: CLOUD_API_DEPLOYED ? CSRF_COOKIE_DOMAIN : undefined,
  });
}

export async function fetcher(url: string, init: RequestInit = {}) {
  const response = await fetch(url, init);
  checkStatus(response);
  updateCSRFTokens(response);
  const res = await parseResponse(response);
  return checkAPIStatus(res);
}

export async function graphqlFetcher(url: string, init: RequestInit = {}) {
  const response = await fetch(url, init);
  updateCSRFTokens(response);
  const parsedResponse = await parseResponse(response);
  checkAPIStatus(parsedResponse, true);
  return parsedResponse;
}

// We only want one refresh request to happen at time globally, since each request will rewrite
// access_token_cookie & csrf_access_token. This attempts to fix an issue that
// resulted in a CSRF mismatch error when two refresh requests raced each other.
let isRefreshing = false; // a synchronous marker to check if the refreshPromise is pending
let refreshPromise = new Promise(() => {});
const waitOnRefresh = async () => {
  // Trigger an auth refresh or join any active refresh request
  if (!isRefreshing) {
    isRefreshing = true;
    refreshPromise = sendRequest(
      endpoints.REFRESH_AUTH,
      { clientKey: "web_js_client" },
      // refresh_token_cookie also gets included with this request automatically
      { headers: { "X-CSRF-TOKEN": getCSRFRefreshToken() } }
    ).finally(() => {
      isRefreshing = false;
    });
  }
  await refreshPromise;
};

export async function requestWithAuth(
  url: string,
  init: RequestInit = {},
  _fetcher: (url: string, init: RequestInit) => Promise<any> = fetcher
) {
  var retriesRemaining = 3;
  var backoffTime = 200;

  while (true) {
    try {
      const authInit: RequestInit = {
        ...init,
        credentials: "include",
        headers: {
          // access_token_cookie also gets included with this request automatically
          "X-CSRF-TOKEN": getCSRFAccessToken(),
          ...init.headers,
        },
      };
      return await _fetcher(url, authInit);
    } catch (error) {
      if (error instanceof FetchError && error.code) {
        const errorcode = parseInt(error.code);
        if (
          errorcode === CloudErrorCode.Enum.REFRESH_REQUIRED ||
          errorcode === CloudErrorCode.Enum.UNAUTHENTICATED
        ) {
          if (url.endsWith(endpoints.REFRESH_AUTH.pathSpec as string)) {
            // We've already attempted to refresh the token; if we're getting the same error again that
            // means the refresh token has also expired and the user needs to re-login
            throw error;
          }
          if (retriesRemaining > 0) {
            // try refreshing again
            await waitOnRefresh();
            // Wait backofftime
            await sleep(backoffTime);
            // Change thresholds for next loop
            retriesRemaining--;
            backoffTime = backoffTime << 1;
            // and we're off to the top again.
          } else {
            // We've tried enough, just error.
            throw error;
          }
        } else {
          // FetchError, but not refresh or unauthenticated.
          // if it's not a refresh error just throw it again
          throw error;
        }
      } else {
        // Not a FetchError.
        throw error;
      }
    }
  }
}

const convertMixpanelKeys = (value: string, level: number) => {
  if (level === 1) {
    return value;
  }
  if (value.startsWith("$")) {
    return `$${_.camelCase(value)}`;
  }
  return _.camelCase(value);
};

export const sendRequest = makeSendRequest(requestWithAuth, CLOUD_API_URL);
export const sendMixpanelEventsRequest = makeSendRequest(
  requestWithAuth,
  CLOUD_API_URL,
  convertMixpanelKeys
);
export const sendRequestPreserveKeys = makeSendRequest(requestWithAuth, CLOUD_API_URL, false);
