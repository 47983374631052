// source: pbtypes/infrastructure/wifi/wifi.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_handhawk_main_radio_type_pb = require('../../../pbtypes/gen/handhawk_main/radio_type_pb.js');
goog.object.extend(proto, pbtypes_gen_handhawk_main_radio_type_pb);
var pbtypes_gen_microhard_util_microhard_lan_settings_pb = require('../../../pbtypes/gen/microhard_util/microhard_lan_settings_pb.js');
goog.object.extend(proto, pbtypes_gen_microhard_util_microhard_lan_settings_pb);
var pbtypes_gen_vehicle_attributes_device_type_pb = require('../../../pbtypes/gen/vehicle_attributes/device_type_pb.js');
goog.object.extend(proto, pbtypes_gen_vehicle_attributes_device_type_pb);
goog.exportSymbol('proto.wifi.AmtelecomDongleStatus', null, global);
goog.exportSymbol('proto.wifi.IpsecPolicy', null, global);
goog.exportSymbol('proto.wifi.RadioClientStatus', null, global);
goog.exportSymbol('proto.wifi.RadioConnectionState', null, global);
goog.exportSymbol('proto.wifi.RadioState', null, global);
goog.exportSymbol('proto.wifi.SkysticksBeaconTunnelStatus', null, global);
goog.exportSymbol('proto.wifi.SkysticksDeviceInfo', null, global);
goog.exportSymbol('proto.wifi.SkysticksPairingInfo', null, global);
goog.exportSymbol('proto.wifi.SkysticksRadioStatus', null, global);
goog.exportSymbol('proto.wifi.UplinkTbttModeConfig', null, global);
goog.exportSymbol('proto.wifi.WifiCredentials', null, global);
goog.exportSymbol('proto.wifi.WifiCredentialsList', null, global);
goog.exportSymbol('proto.wifi.WifiCredentialsList.WifiCredentialsType', null, global);
goog.exportSymbol('proto.wifi.WifiViRetryRate', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wifi.WifiCredentials = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wifi.WifiCredentials, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wifi.WifiCredentials.displayName = 'proto.wifi.WifiCredentials';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wifi.WifiCredentialsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wifi.WifiCredentialsList.repeatedFields_, null);
};
goog.inherits(proto.wifi.WifiCredentialsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wifi.WifiCredentialsList.displayName = 'proto.wifi.WifiCredentialsList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wifi.SkysticksDeviceInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wifi.SkysticksDeviceInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wifi.SkysticksDeviceInfo.displayName = 'proto.wifi.SkysticksDeviceInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wifi.SkysticksPairingInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wifi.SkysticksPairingInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wifi.SkysticksPairingInfo.displayName = 'proto.wifi.SkysticksPairingInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wifi.SkysticksRadioStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wifi.SkysticksRadioStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wifi.SkysticksRadioStatus.displayName = 'proto.wifi.SkysticksRadioStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wifi.SkysticksBeaconTunnelStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wifi.SkysticksBeaconTunnelStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wifi.SkysticksBeaconTunnelStatus.displayName = 'proto.wifi.SkysticksBeaconTunnelStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wifi.WifiViRetryRate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wifi.WifiViRetryRate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wifi.WifiViRetryRate.displayName = 'proto.wifi.WifiViRetryRate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wifi.UplinkTbttModeConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wifi.UplinkTbttModeConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wifi.UplinkTbttModeConfig.displayName = 'proto.wifi.UplinkTbttModeConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wifi.AmtelecomDongleStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wifi.AmtelecomDongleStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wifi.AmtelecomDongleStatus.displayName = 'proto.wifi.AmtelecomDongleStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wifi.RadioClientStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wifi.RadioClientStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wifi.RadioClientStatus.displayName = 'proto.wifi.RadioClientStatus';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wifi.WifiCredentials.prototype.toObject = function(opt_includeInstance) {
  return proto.wifi.WifiCredentials.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wifi.WifiCredentials} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wifi.WifiCredentials.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
networkId: jspb.Message.getFieldWithDefault(msg, 2, ""),
bssid: jspb.Message.getFieldWithDefault(msg, 3, ""),
password: jspb.Message.getFieldWithDefault(msg, 4, ""),
hashedPsk: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
desiredIpsec: jspb.Message.getFieldWithDefault(msg, 6, 0),
uplinkEncKey: jspb.Message.getFieldWithDefault(msg, 7, ""),
downlinkEncKey: jspb.Message.getFieldWithDefault(msg, 8, ""),
uplinkAuthKey: jspb.Message.getFieldWithDefault(msg, 9, ""),
downlinkAuthKey: jspb.Message.getFieldWithDefault(msg, 10, ""),
macAddress: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wifi.WifiCredentials}
 */
proto.wifi.WifiCredentials.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wifi.WifiCredentials;
  return proto.wifi.WifiCredentials.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wifi.WifiCredentials} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wifi.WifiCredentials}
 */
proto.wifi.WifiCredentials.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetworkId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBssid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHashedPsk(value);
      break;
    case 6:
      var value = /** @type {!proto.wifi.IpsecPolicy} */ (reader.readEnum());
      msg.setDesiredIpsec(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUplinkEncKey(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDownlinkEncKey(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUplinkAuthKey(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDownlinkAuthKey(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setMacAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wifi.WifiCredentials.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wifi.WifiCredentials.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wifi.WifiCredentials} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wifi.WifiCredentials.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNetworkId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBssid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getHashedPsk();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getDesiredIpsec();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getUplinkEncKey();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDownlinkEncKey();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUplinkAuthKey();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDownlinkAuthKey();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMacAddress();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.wifi.WifiCredentials.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wifi.WifiCredentials} returns this
 */
proto.wifi.WifiCredentials.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string network_id = 2;
 * @return {string}
 */
proto.wifi.WifiCredentials.prototype.getNetworkId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wifi.WifiCredentials} returns this
 */
proto.wifi.WifiCredentials.prototype.setNetworkId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string bssid = 3;
 * @return {string}
 */
proto.wifi.WifiCredentials.prototype.getBssid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wifi.WifiCredentials} returns this
 */
proto.wifi.WifiCredentials.prototype.setBssid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string password = 4;
 * @return {string}
 */
proto.wifi.WifiCredentials.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wifi.WifiCredentials} returns this
 */
proto.wifi.WifiCredentials.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool hashed_psk = 5;
 * @return {boolean}
 */
proto.wifi.WifiCredentials.prototype.getHashedPsk = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wifi.WifiCredentials} returns this
 */
proto.wifi.WifiCredentials.prototype.setHashedPsk = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional IpsecPolicy desired_ipsec = 6;
 * @return {!proto.wifi.IpsecPolicy}
 */
proto.wifi.WifiCredentials.prototype.getDesiredIpsec = function() {
  return /** @type {!proto.wifi.IpsecPolicy} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wifi.IpsecPolicy} value
 * @return {!proto.wifi.WifiCredentials} returns this
 */
proto.wifi.WifiCredentials.prototype.setDesiredIpsec = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string uplink_enc_key = 7;
 * @return {string}
 */
proto.wifi.WifiCredentials.prototype.getUplinkEncKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wifi.WifiCredentials} returns this
 */
proto.wifi.WifiCredentials.prototype.setUplinkEncKey = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string downlink_enc_key = 8;
 * @return {string}
 */
proto.wifi.WifiCredentials.prototype.getDownlinkEncKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wifi.WifiCredentials} returns this
 */
proto.wifi.WifiCredentials.prototype.setDownlinkEncKey = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string uplink_auth_key = 9;
 * @return {string}
 */
proto.wifi.WifiCredentials.prototype.getUplinkAuthKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wifi.WifiCredentials} returns this
 */
proto.wifi.WifiCredentials.prototype.setUplinkAuthKey = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string downlink_auth_key = 10;
 * @return {string}
 */
proto.wifi.WifiCredentials.prototype.getDownlinkAuthKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wifi.WifiCredentials} returns this
 */
proto.wifi.WifiCredentials.prototype.setDownlinkAuthKey = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string mac_address = 11;
 * @return {string}
 */
proto.wifi.WifiCredentials.prototype.getMacAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wifi.WifiCredentials} returns this
 */
proto.wifi.WifiCredentials.prototype.setMacAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wifi.WifiCredentialsList.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wifi.WifiCredentialsList.prototype.toObject = function(opt_includeInstance) {
  return proto.wifi.WifiCredentialsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wifi.WifiCredentialsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wifi.WifiCredentialsList.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
wifiCredentialsList: jspb.Message.toObjectList(msg.getWifiCredentialsList(),
    proto.wifi.WifiCredentials.toObject, includeInstance),
wifiCredentialsType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wifi.WifiCredentialsList}
 */
proto.wifi.WifiCredentialsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wifi.WifiCredentialsList;
  return proto.wifi.WifiCredentialsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wifi.WifiCredentialsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wifi.WifiCredentialsList}
 */
proto.wifi.WifiCredentialsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new proto.wifi.WifiCredentials;
      reader.readMessage(value,proto.wifi.WifiCredentials.deserializeBinaryFromReader);
      msg.addWifiCredentials(value);
      break;
    case 3:
      var value = /** @type {!proto.wifi.WifiCredentialsList.WifiCredentialsType} */ (reader.readEnum());
      msg.setWifiCredentialsType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wifi.WifiCredentialsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wifi.WifiCredentialsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wifi.WifiCredentialsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wifi.WifiCredentialsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWifiCredentialsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wifi.WifiCredentials.serializeBinaryToWriter
    );
  }
  f = message.getWifiCredentialsType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.wifi.WifiCredentialsList.WifiCredentialsType = {
  UNKNOWN: 0,
  INFRASTRUCTURE: 1,
  VEHICLE: 2
};

/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.wifi.WifiCredentialsList.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wifi.WifiCredentialsList} returns this
 */
proto.wifi.WifiCredentialsList.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated WifiCredentials wifi_credentials = 2;
 * @return {!Array<!proto.wifi.WifiCredentials>}
 */
proto.wifi.WifiCredentialsList.prototype.getWifiCredentialsList = function() {
  return /** @type{!Array<!proto.wifi.WifiCredentials>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wifi.WifiCredentials, 2));
};


/**
 * @param {!Array<!proto.wifi.WifiCredentials>} value
 * @return {!proto.wifi.WifiCredentialsList} returns this
*/
proto.wifi.WifiCredentialsList.prototype.setWifiCredentialsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wifi.WifiCredentials=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wifi.WifiCredentials}
 */
proto.wifi.WifiCredentialsList.prototype.addWifiCredentials = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wifi.WifiCredentials, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wifi.WifiCredentialsList} returns this
 */
proto.wifi.WifiCredentialsList.prototype.clearWifiCredentialsList = function() {
  return this.setWifiCredentialsList([]);
};


/**
 * optional WifiCredentialsType wifi_credentials_type = 3;
 * @return {!proto.wifi.WifiCredentialsList.WifiCredentialsType}
 */
proto.wifi.WifiCredentialsList.prototype.getWifiCredentialsType = function() {
  return /** @type {!proto.wifi.WifiCredentialsList.WifiCredentialsType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wifi.WifiCredentialsList.WifiCredentialsType} value
 * @return {!proto.wifi.WifiCredentialsList} returns this
 */
proto.wifi.WifiCredentialsList.prototype.setWifiCredentialsType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wifi.SkysticksDeviceInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.wifi.SkysticksDeviceInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wifi.SkysticksDeviceInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wifi.SkysticksDeviceInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
serial: jspb.Message.getFieldWithDefault(msg, 2, ""),
radioType: jspb.Message.getFieldWithDefault(msg, 3, 0),
desiredIpsec: jspb.Message.getFieldWithDefault(msg, 4, 0),
macAddress: jspb.Message.getFieldWithDefault(msg, 5, ""),
deviceType: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wifi.SkysticksDeviceInfo}
 */
proto.wifi.SkysticksDeviceInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wifi.SkysticksDeviceInfo;
  return proto.wifi.SkysticksDeviceInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wifi.SkysticksDeviceInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wifi.SkysticksDeviceInfo}
 */
proto.wifi.SkysticksDeviceInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerial(value);
      break;
    case 3:
      var value = /** @type {!proto.handhawk_main.RadioType.Enum} */ (reader.readEnum());
      msg.setRadioType(value);
      break;
    case 4:
      var value = /** @type {!proto.wifi.IpsecPolicy} */ (reader.readEnum());
      msg.setDesiredIpsec(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMacAddress(value);
      break;
    case 6:
      var value = /** @type {!proto.vehicle_attributes.DeviceType.Enum} */ (reader.readEnum());
      msg.setDeviceType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wifi.SkysticksDeviceInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wifi.SkysticksDeviceInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wifi.SkysticksDeviceInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wifi.SkysticksDeviceInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSerial();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRadioType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDesiredIpsec();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getMacAddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDeviceType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.wifi.SkysticksDeviceInfo.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wifi.SkysticksDeviceInfo} returns this
 */
proto.wifi.SkysticksDeviceInfo.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string serial = 2;
 * @return {string}
 */
proto.wifi.SkysticksDeviceInfo.prototype.getSerial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wifi.SkysticksDeviceInfo} returns this
 */
proto.wifi.SkysticksDeviceInfo.prototype.setSerial = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional handhawk_main.RadioType.Enum radio_type = 3;
 * @return {!proto.handhawk_main.RadioType.Enum}
 */
proto.wifi.SkysticksDeviceInfo.prototype.getRadioType = function() {
  return /** @type {!proto.handhawk_main.RadioType.Enum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.handhawk_main.RadioType.Enum} value
 * @return {!proto.wifi.SkysticksDeviceInfo} returns this
 */
proto.wifi.SkysticksDeviceInfo.prototype.setRadioType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional IpsecPolicy desired_ipsec = 4;
 * @return {!proto.wifi.IpsecPolicy}
 */
proto.wifi.SkysticksDeviceInfo.prototype.getDesiredIpsec = function() {
  return /** @type {!proto.wifi.IpsecPolicy} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wifi.IpsecPolicy} value
 * @return {!proto.wifi.SkysticksDeviceInfo} returns this
 */
proto.wifi.SkysticksDeviceInfo.prototype.setDesiredIpsec = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string mac_address = 5;
 * @return {string}
 */
proto.wifi.SkysticksDeviceInfo.prototype.getMacAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wifi.SkysticksDeviceInfo} returns this
 */
proto.wifi.SkysticksDeviceInfo.prototype.setMacAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional vehicle_attributes.DeviceType.Enum device_type = 6;
 * @return {!proto.vehicle_attributes.DeviceType.Enum}
 */
proto.wifi.SkysticksDeviceInfo.prototype.getDeviceType = function() {
  return /** @type {!proto.vehicle_attributes.DeviceType.Enum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.vehicle_attributes.DeviceType.Enum} value
 * @return {!proto.wifi.SkysticksDeviceInfo} returns this
 */
proto.wifi.SkysticksDeviceInfo.prototype.setDeviceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wifi.SkysticksPairingInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.wifi.SkysticksPairingInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wifi.SkysticksPairingInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wifi.SkysticksPairingInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
vehicleName: jspb.Message.getFieldWithDefault(msg, 2, ""),
vehicleSerial: jspb.Message.getFieldWithDefault(msg, 13, ""),
radioType: jspb.Message.getFieldWithDefault(msg, 3, 0),
credentials: (f = msg.getCredentials()) && proto.wifi.WifiCredentials.toObject(includeInstance, f),
bandwidth: jspb.Message.getFieldWithDefault(msg, 8, 0),
channel: jspb.Message.getFieldWithDefault(msg, 9, 0),
band: jspb.Message.getFieldWithDefault(msg, 10, 0),
mode: jspb.Message.getFieldWithDefault(msg, 11, 0),
powerKnob: jspb.Message.getFieldWithDefault(msg, 12, 0),
microhardLanSettings: (f = msg.getMicrohardLanSettings()) && pbtypes_gen_microhard_util_microhard_lan_settings_pb.MicrohardLanSettingsPb.toObject(includeInstance, f),
isNonSkysticksPairing: jspb.Message.getBooleanFieldWithDefault(msg, 14, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wifi.SkysticksPairingInfo}
 */
proto.wifi.SkysticksPairingInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wifi.SkysticksPairingInfo;
  return proto.wifi.SkysticksPairingInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wifi.SkysticksPairingInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wifi.SkysticksPairingInfo}
 */
proto.wifi.SkysticksPairingInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleName(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleSerial(value);
      break;
    case 3:
      var value = /** @type {!proto.handhawk_main.RadioType.Enum} */ (reader.readEnum());
      msg.setRadioType(value);
      break;
    case 4:
      var value = new proto.wifi.WifiCredentials;
      reader.readMessage(value,proto.wifi.WifiCredentials.deserializeBinaryFromReader);
      msg.setCredentials(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBandwidth(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChannel(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBand(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMode(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPowerKnob(value);
      break;
    case 15:
      var value = new pbtypes_gen_microhard_util_microhard_lan_settings_pb.MicrohardLanSettingsPb;
      reader.readMessage(value,pbtypes_gen_microhard_util_microhard_lan_settings_pb.MicrohardLanSettingsPb.deserializeBinaryFromReader);
      msg.setMicrohardLanSettings(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsNonSkysticksPairing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wifi.SkysticksPairingInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wifi.SkysticksPairingInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wifi.SkysticksPairingInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wifi.SkysticksPairingInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getVehicleName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVehicleSerial();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getRadioType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCredentials();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.wifi.WifiCredentials.serializeBinaryToWriter
    );
  }
  f = message.getBandwidth();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getChannel();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getBand();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getMode();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getPowerKnob();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getMicrohardLanSettings();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      pbtypes_gen_microhard_util_microhard_lan_settings_pb.MicrohardLanSettingsPb.serializeBinaryToWriter
    );
  }
  f = message.getIsNonSkysticksPairing();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.wifi.SkysticksPairingInfo.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wifi.SkysticksPairingInfo} returns this
 */
proto.wifi.SkysticksPairingInfo.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string vehicle_name = 2;
 * @return {string}
 */
proto.wifi.SkysticksPairingInfo.prototype.getVehicleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wifi.SkysticksPairingInfo} returns this
 */
proto.wifi.SkysticksPairingInfo.prototype.setVehicleName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string vehicle_serial = 13;
 * @return {string}
 */
proto.wifi.SkysticksPairingInfo.prototype.getVehicleSerial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.wifi.SkysticksPairingInfo} returns this
 */
proto.wifi.SkysticksPairingInfo.prototype.setVehicleSerial = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional handhawk_main.RadioType.Enum radio_type = 3;
 * @return {!proto.handhawk_main.RadioType.Enum}
 */
proto.wifi.SkysticksPairingInfo.prototype.getRadioType = function() {
  return /** @type {!proto.handhawk_main.RadioType.Enum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.handhawk_main.RadioType.Enum} value
 * @return {!proto.wifi.SkysticksPairingInfo} returns this
 */
proto.wifi.SkysticksPairingInfo.prototype.setRadioType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional WifiCredentials credentials = 4;
 * @return {?proto.wifi.WifiCredentials}
 */
proto.wifi.SkysticksPairingInfo.prototype.getCredentials = function() {
  return /** @type{?proto.wifi.WifiCredentials} */ (
    jspb.Message.getWrapperField(this, proto.wifi.WifiCredentials, 4));
};


/**
 * @param {?proto.wifi.WifiCredentials|undefined} value
 * @return {!proto.wifi.SkysticksPairingInfo} returns this
*/
proto.wifi.SkysticksPairingInfo.prototype.setCredentials = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wifi.SkysticksPairingInfo} returns this
 */
proto.wifi.SkysticksPairingInfo.prototype.clearCredentials = function() {
  return this.setCredentials(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wifi.SkysticksPairingInfo.prototype.hasCredentials = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 bandwidth = 8;
 * @return {number}
 */
proto.wifi.SkysticksPairingInfo.prototype.getBandwidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.wifi.SkysticksPairingInfo} returns this
 */
proto.wifi.SkysticksPairingInfo.prototype.setBandwidth = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 channel = 9;
 * @return {number}
 */
proto.wifi.SkysticksPairingInfo.prototype.getChannel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.wifi.SkysticksPairingInfo} returns this
 */
proto.wifi.SkysticksPairingInfo.prototype.setChannel = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 band = 10;
 * @return {number}
 */
proto.wifi.SkysticksPairingInfo.prototype.getBand = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.wifi.SkysticksPairingInfo} returns this
 */
proto.wifi.SkysticksPairingInfo.prototype.setBand = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 mode = 11;
 * @return {number}
 */
proto.wifi.SkysticksPairingInfo.prototype.getMode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.wifi.SkysticksPairingInfo} returns this
 */
proto.wifi.SkysticksPairingInfo.prototype.setMode = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 power_knob = 12;
 * @return {number}
 */
proto.wifi.SkysticksPairingInfo.prototype.getPowerKnob = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.wifi.SkysticksPairingInfo} returns this
 */
proto.wifi.SkysticksPairingInfo.prototype.setPowerKnob = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional microhard_util.MicrohardLanSettingsPb microhard_lan_settings = 15;
 * @return {?proto.microhard_util.MicrohardLanSettingsPb}
 */
proto.wifi.SkysticksPairingInfo.prototype.getMicrohardLanSettings = function() {
  return /** @type{?proto.microhard_util.MicrohardLanSettingsPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_microhard_util_microhard_lan_settings_pb.MicrohardLanSettingsPb, 15));
};


/**
 * @param {?proto.microhard_util.MicrohardLanSettingsPb|undefined} value
 * @return {!proto.wifi.SkysticksPairingInfo} returns this
*/
proto.wifi.SkysticksPairingInfo.prototype.setMicrohardLanSettings = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wifi.SkysticksPairingInfo} returns this
 */
proto.wifi.SkysticksPairingInfo.prototype.clearMicrohardLanSettings = function() {
  return this.setMicrohardLanSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wifi.SkysticksPairingInfo.prototype.hasMicrohardLanSettings = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional bool is_non_skysticks_pairing = 14;
 * @return {boolean}
 */
proto.wifi.SkysticksPairingInfo.prototype.getIsNonSkysticksPairing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wifi.SkysticksPairingInfo} returns this
 */
proto.wifi.SkysticksPairingInfo.prototype.setIsNonSkysticksPairing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wifi.SkysticksRadioStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.wifi.SkysticksRadioStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wifi.SkysticksRadioStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wifi.SkysticksRadioStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
state: jspb.Message.getFieldWithDefault(msg, 2, 0),
vehicleRadioType: jspb.Message.getFieldWithDefault(msg, 3, 0),
controllerRadioType: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wifi.SkysticksRadioStatus}
 */
proto.wifi.SkysticksRadioStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wifi.SkysticksRadioStatus;
  return proto.wifi.SkysticksRadioStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wifi.SkysticksRadioStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wifi.SkysticksRadioStatus}
 */
proto.wifi.SkysticksRadioStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {!proto.wifi.RadioState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 3:
      var value = /** @type {!proto.handhawk_main.RadioType.Enum} */ (reader.readEnum());
      msg.setVehicleRadioType(value);
      break;
    case 4:
      var value = /** @type {!proto.handhawk_main.RadioType.Enum} */ (reader.readEnum());
      msg.setControllerRadioType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wifi.SkysticksRadioStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wifi.SkysticksRadioStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wifi.SkysticksRadioStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wifi.SkysticksRadioStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getVehicleRadioType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getControllerRadioType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.wifi.SkysticksRadioStatus.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wifi.SkysticksRadioStatus} returns this
 */
proto.wifi.SkysticksRadioStatus.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional RadioState state = 2;
 * @return {!proto.wifi.RadioState}
 */
proto.wifi.SkysticksRadioStatus.prototype.getState = function() {
  return /** @type {!proto.wifi.RadioState} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wifi.RadioState} value
 * @return {!proto.wifi.SkysticksRadioStatus} returns this
 */
proto.wifi.SkysticksRadioStatus.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional handhawk_main.RadioType.Enum vehicle_radio_type = 3;
 * @return {!proto.handhawk_main.RadioType.Enum}
 */
proto.wifi.SkysticksRadioStatus.prototype.getVehicleRadioType = function() {
  return /** @type {!proto.handhawk_main.RadioType.Enum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.handhawk_main.RadioType.Enum} value
 * @return {!proto.wifi.SkysticksRadioStatus} returns this
 */
proto.wifi.SkysticksRadioStatus.prototype.setVehicleRadioType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional handhawk_main.RadioType.Enum controller_radio_type = 4;
 * @return {!proto.handhawk_main.RadioType.Enum}
 */
proto.wifi.SkysticksRadioStatus.prototype.getControllerRadioType = function() {
  return /** @type {!proto.handhawk_main.RadioType.Enum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.handhawk_main.RadioType.Enum} value
 * @return {!proto.wifi.SkysticksRadioStatus} returns this
 */
proto.wifi.SkysticksRadioStatus.prototype.setControllerRadioType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wifi.SkysticksBeaconTunnelStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.wifi.SkysticksBeaconTunnelStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wifi.SkysticksBeaconTunnelStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wifi.SkysticksBeaconTunnelStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
ackTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
recvTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
sendTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
lastActivityTime: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wifi.SkysticksBeaconTunnelStatus}
 */
proto.wifi.SkysticksBeaconTunnelStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wifi.SkysticksBeaconTunnelStatus;
  return proto.wifi.SkysticksBeaconTunnelStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wifi.SkysticksBeaconTunnelStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wifi.SkysticksBeaconTunnelStatus}
 */
proto.wifi.SkysticksBeaconTunnelStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAckTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRecvTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSendTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastActivityTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wifi.SkysticksBeaconTunnelStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wifi.SkysticksBeaconTunnelStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wifi.SkysticksBeaconTunnelStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wifi.SkysticksBeaconTunnelStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAckTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRecvTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSendTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getLastActivityTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 ack_time = 1;
 * @return {number}
 */
proto.wifi.SkysticksBeaconTunnelStatus.prototype.getAckTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wifi.SkysticksBeaconTunnelStatus} returns this
 */
proto.wifi.SkysticksBeaconTunnelStatus.prototype.setAckTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 recv_time = 2;
 * @return {number}
 */
proto.wifi.SkysticksBeaconTunnelStatus.prototype.getRecvTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wifi.SkysticksBeaconTunnelStatus} returns this
 */
proto.wifi.SkysticksBeaconTunnelStatus.prototype.setRecvTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 send_time = 3;
 * @return {number}
 */
proto.wifi.SkysticksBeaconTunnelStatus.prototype.getSendTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wifi.SkysticksBeaconTunnelStatus} returns this
 */
proto.wifi.SkysticksBeaconTunnelStatus.prototype.setSendTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 last_activity_time = 4;
 * @return {number}
 */
proto.wifi.SkysticksBeaconTunnelStatus.prototype.getLastActivityTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wifi.SkysticksBeaconTunnelStatus} returns this
 */
proto.wifi.SkysticksBeaconTunnelStatus.prototype.setLastActivityTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wifi.WifiViRetryRate.prototype.toObject = function(opt_includeInstance) {
  return proto.wifi.WifiViRetryRate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wifi.WifiViRetryRate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wifi.WifiViRetryRate.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
viRetryRate: jspb.Message.getFieldWithDefault(msg, 2, 0),
retryAvg: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
dupAvg: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wifi.WifiViRetryRate}
 */
proto.wifi.WifiViRetryRate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wifi.WifiViRetryRate;
  return proto.wifi.WifiViRetryRate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wifi.WifiViRetryRate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wifi.WifiViRetryRate}
 */
proto.wifi.WifiViRetryRate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setViRetryRate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRetryAvg(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDupAvg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wifi.WifiViRetryRate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wifi.WifiViRetryRate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wifi.WifiViRetryRate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wifi.WifiViRetryRate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getViRetryRate();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getRetryAvg();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getDupAvg();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.wifi.WifiViRetryRate.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wifi.WifiViRetryRate} returns this
 */
proto.wifi.WifiViRetryRate.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 vi_retry_rate = 2;
 * @return {number}
 */
proto.wifi.WifiViRetryRate.prototype.getViRetryRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wifi.WifiViRetryRate} returns this
 */
proto.wifi.WifiViRetryRate.prototype.setViRetryRate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float retry_avg = 3;
 * @return {number}
 */
proto.wifi.WifiViRetryRate.prototype.getRetryAvg = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wifi.WifiViRetryRate} returns this
 */
proto.wifi.WifiViRetryRate.prototype.setRetryAvg = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float dup_avg = 4;
 * @return {number}
 */
proto.wifi.WifiViRetryRate.prototype.getDupAvg = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wifi.WifiViRetryRate} returns this
 */
proto.wifi.WifiViRetryRate.prototype.setDupAvg = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wifi.UplinkTbttModeConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.wifi.UplinkTbttModeConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wifi.UplinkTbttModeConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wifi.UplinkTbttModeConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
tbttMode: jspb.Message.getFieldWithDefault(msg, 2, 0),
uplinkCadence: jspb.Message.getFieldWithDefault(msg, 3, 0),
uplinkPacketSize: jspb.Message.getFieldWithDefault(msg, 4, 0),
isMh: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wifi.UplinkTbttModeConfig}
 */
proto.wifi.UplinkTbttModeConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wifi.UplinkTbttModeConfig;
  return proto.wifi.UplinkTbttModeConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wifi.UplinkTbttModeConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wifi.UplinkTbttModeConfig}
 */
proto.wifi.UplinkTbttModeConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTbttMode(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUplinkCadence(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUplinkPacketSize(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMh(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wifi.UplinkTbttModeConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wifi.UplinkTbttModeConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wifi.UplinkTbttModeConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wifi.UplinkTbttModeConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTbttMode();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getUplinkCadence();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getUplinkPacketSize();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getIsMh();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.wifi.UplinkTbttModeConfig.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wifi.UplinkTbttModeConfig} returns this
 */
proto.wifi.UplinkTbttModeConfig.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 tbtt_mode = 2;
 * @return {number}
 */
proto.wifi.UplinkTbttModeConfig.prototype.getTbttMode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wifi.UplinkTbttModeConfig} returns this
 */
proto.wifi.UplinkTbttModeConfig.prototype.setTbttMode = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 uplink_cadence = 3;
 * @return {number}
 */
proto.wifi.UplinkTbttModeConfig.prototype.getUplinkCadence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wifi.UplinkTbttModeConfig} returns this
 */
proto.wifi.UplinkTbttModeConfig.prototype.setUplinkCadence = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 uplink_packet_size = 4;
 * @return {number}
 */
proto.wifi.UplinkTbttModeConfig.prototype.getUplinkPacketSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wifi.UplinkTbttModeConfig} returns this
 */
proto.wifi.UplinkTbttModeConfig.prototype.setUplinkPacketSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool is_mh = 5;
 * @return {boolean}
 */
proto.wifi.UplinkTbttModeConfig.prototype.getIsMh = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wifi.UplinkTbttModeConfig} returns this
 */
proto.wifi.UplinkTbttModeConfig.prototype.setIsMh = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wifi.AmtelecomDongleStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.wifi.AmtelecomDongleStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wifi.AmtelecomDongleStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wifi.AmtelecomDongleStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
status: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wifi.AmtelecomDongleStatus}
 */
proto.wifi.AmtelecomDongleStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wifi.AmtelecomDongleStatus;
  return proto.wifi.AmtelecomDongleStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wifi.AmtelecomDongleStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wifi.AmtelecomDongleStatus}
 */
proto.wifi.AmtelecomDongleStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wifi.AmtelecomDongleStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wifi.AmtelecomDongleStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wifi.AmtelecomDongleStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wifi.AmtelecomDongleStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.wifi.AmtelecomDongleStatus.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wifi.AmtelecomDongleStatus} returns this
 */
proto.wifi.AmtelecomDongleStatus.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool status = 2;
 * @return {boolean}
 */
proto.wifi.AmtelecomDongleStatus.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wifi.AmtelecomDongleStatus} returns this
 */
proto.wifi.AmtelecomDongleStatus.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wifi.RadioClientStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.wifi.RadioClientStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wifi.RadioClientStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wifi.RadioClientStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
status: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wifi.RadioClientStatus}
 */
proto.wifi.RadioClientStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wifi.RadioClientStatus;
  return proto.wifi.RadioClientStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wifi.RadioClientStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wifi.RadioClientStatus}
 */
proto.wifi.RadioClientStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {!proto.wifi.RadioConnectionState} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wifi.RadioClientStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wifi.RadioClientStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wifi.RadioClientStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wifi.RadioClientStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.wifi.RadioClientStatus.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wifi.RadioClientStatus} returns this
 */
proto.wifi.RadioClientStatus.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional RadioConnectionState status = 2;
 * @return {!proto.wifi.RadioConnectionState}
 */
proto.wifi.RadioClientStatus.prototype.getStatus = function() {
  return /** @type {!proto.wifi.RadioConnectionState} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wifi.RadioConnectionState} value
 * @return {!proto.wifi.RadioClientStatus} returns this
 */
proto.wifi.RadioClientStatus.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.wifi.IpsecPolicy = {
  DISABLED: 0,
  UPLINK_ONLY: 1,
  DOWNLINK_ONLY: 2,
  BIDIRECTIONAL: 3
};

/**
 * @enum {number}
 */
proto.wifi.RadioState = {
  UNKNOWN: 0,
  INITIALIZING: 1,
  READY: 2
};

/**
 * @enum {number}
 */
proto.wifi.RadioConnectionState = {
  KINVALID: 0,
  KUSBCONNECTED: 1,
  KPAIRINGINFORECEIVED: 2,
  KCONNECTED: 3,
  KDISCONNECTED: 4,
  KPAIRINGTIMEOUT: 5
};

goog.object.extend(exports, proto.wifi);
