import { createSelector } from "reselect";
import _ from "lodash";
import moment from "moment";

import { paginationStateToProps } from "../pagination/utils";
import { flightInitialState, FlightsState } from "./slice";
import { FLIGHT_STAT_RANGES_GROUP_BY, getStatsIdTypeKey } from "./utils";
import { FlightStatsDuration, FlightsRequest } from "./types";

export const getFlightsQueryTimeout = (state: FlightsState) => state.dispatchTimeout;

export const getAllFlights = (state: FlightsState) => state.flights;
export const getFlight = (state: FlightsState, flightId: string) =>
  state.flights[flightId] ?? flightInitialState;
const getFlightsRequestIds = (state: FlightsState) => state.requestedIds;

export const getFlightsForRequest = createSelector(
  getFlightsRequestIds,
  getAllFlights,
  (flightIds, allFlights) => flightIds.map(flightId => allFlights[flightId])
);

export const getFlightsRequest = (state: FlightsState) => state.requests.flights;

export const isFlightsRequestActive = (state: FlightsState) => getFlightsRequest(state).active;

export const isFlightRequestActive = (state: FlightsState, flightId: string) =>
  state.requests.flight[flightId]?.active ?? false;

export const getFlightTakeoffUtime = (state: FlightsState, flightId: string) =>
  getFlight(state, flightId)?.takeoffUtime;

// Time difference between uclock (absolute) and utime (relative)
export const getFlightUtimeOffset = (state: FlightsState, flightId: string) => {
  const { takeoffUtime, takeoffUclock } = getFlight(state, flightId);
  return takeoffUclock - takeoffUtime;
};

const getFlightStats = (state: FlightsState) => state.stats;

export const getFlightStatsByVehicle = createSelector(getFlightStats, stats => stats.VEHICLE ?? {});

export const getFlightStatsOverTime = (state: FlightsState, duration: FlightStatsDuration) =>
  getFlightStats(state)[getStatsIdTypeKey(FLIGHT_STAT_RANGES_GROUP_BY[duration])] ?? {};

export const getFlightsPagination = (state: FlightsState) => state.pagination;

export const getFlightsPaginationProps = createSelector(
  getFlightsPagination,
  paginationStateToProps
);

export const getRequestHasChanged = (state: FlightsState, request: FlightsRequest) =>
  !_.isEqual(state.requestState, request);

export const getFlightVehicleId = (state: FlightsState, flightId: string) =>
  getFlight(state, flightId).vehicleId;

export const getFlightDuration = (state: FlightsState, flightId: string) => {
  const { takeoff, landing } = getFlight(state, flightId);
  return moment.duration(takeoff && landing ? landing.diff(takeoff) : 0);
};
